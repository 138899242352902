const paths = {
    LOGIN: "/login",
    HOME: "/",
    FORGOT_PASSWORD: "/forgotPassword",
    VOUCHERS: "/vouchers",
    POSTS: "/posts",
    NEW_VOUCHER: "/newVoucher",
    NEW_POST: "/newPost",
    SCAN: "/scan",
    TERMS: "/terms",
    PROFILE: "/profile",
    SALES: "/sales",
    BANK_ACCOUNT: "/bankAccount",
};

export default paths;
