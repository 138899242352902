import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Input from "./../Input";
import Geocode from "react-geocode";

const apiKey = "AIzaSyDXjVw51oWdafgN19tpm2xtemUiGiA4AGg";

const containerStyle = {
    height: "300px",
    width: "100%",
    marginBottom: "20px",
};

const mapStyle = [
    {
        featureType: "all",
        elementType: "all",
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: "poi",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
];

export default function Map({ coordinates, address = "", onAddressChange }) {
    const [map, setMap] = useState(null);
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: apiKey,
    });

    const onLoad = () => {
        setMap(map);
    };

    const onUnmount = () => {
        setMap(null);
    };

    const onClickMap = (e) => {
        Geocode.setApiKey(apiKey);
        Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
            (response) => {
                const newAddress = response.results[0].formatted_address;
                onAddressChange(newAddress, { lat: e.latLng.lat(), lng: e.latLng.lng() });
            },
            (error) => {
                console.error(error);
            },
        );
    };

    return (
        <div className="Map">
            <p className="title">Search Address</p>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordinates}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={{ styles: mapStyle }}
                    onClick={onClickMap}
                >
                    <Marker position={coordinates} />
                </GoogleMap>
            ) : (
                <div className="Map-Placeholder"></div>
            )}
            <Input
                label="Display Address"
                value={address}
                onChange={(e) => onAddressChange(e.target.value, coordinates)}
            />
        </div>
    );
}
