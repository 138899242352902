import ProfileInfo from "./../ProfileInfo";
import Input from "./../Input";
import React, { useState, useEffect, createRef } from "react";
import { formatDate, formatTime } from "../../helpers/formatDate";
import API from "../../APIs/posts";
import DeleteIcon from "./../../assets/images/icons/delete_white.svg";
import Button from "./../Button";

export default function Comment({ comment, addReply, deleteComment }) {
    const [replies, setReplies] = useState([]);
    const replyRef = createRef();
    const [replyInput, setReplyInput] = useState(false);
    const [reply, setReply] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setReplies([]);
        setReply("");
        setReplyInput(false);
    }, [comment]);

    const getReplies = async (id) => {
        try {
            const res = await API.getCommentReplies(id);
            setReplies(res.data.replies);
        } catch (error) {
            console.log("error -> ", error);
        }
    };

    //TODO limit the call of this function
    const checkDate = (date) => {
        const today = new Date();
        const commentDate = new Date(date);
        if (today.setHours(0, 0, 0, 0) > commentDate.setHours(0, 0, 0, 0)) {
            return formatDate(new Date(date));
        } else {
            return "Today at " + formatTime(new Date(date));
        }
    };

    const deleteComentHandler = async (id, isReply) => {
        setLoading(true);
        try {
            await API.deleteComment(id);
            deleteComment(isReply);
            setLoading(false);
        } catch (error) {
            console.log("error -> ", error);
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="Comment">
                <div className="row">
                    <ProfileInfo
                        name={comment.user.name}
                        username={comment.user.username}
                        avatarSrc={comment.user.avatar_url}
                    />
                    <div className="col">
                        <span className="date">
                            {comment.created_at && checkDate(comment.created_at)}
                        </span>
                        <Button
                            deleteBtn
                            iconSrc={DeleteIcon}
                            onClick={() => deleteComentHandler(comment.id, false)}
                            disabled={loading}
                            loading={loading}
                        />
                    </div>
                </div>
                <div className="text">{comment.text}</div>
            </div>
            <div className="reply">
                <span
                    onClick={() => {
                        setReplyInput(!replyInput);
                        setReply("");
                    }}
                >
                    Reply
                </span>
                {replyInput && (
                    <Input
                        ref={replyRef}
                        isTextarea
                        maxLenght="250"
                        hasCounter={reply}
                        placeholder="Add a reply..."
                        hasButton
                        buttonLabel="Post"
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                        onClickEvent={() => addReply(reply)}
                    />
                )}
            </div>
            {comment.replies_num !== 0 && (
                <div className="replies">
                    {replies.length === 0 ? (
                        <p className="view" onClick={() => getReplies(comment.id)}>
                            {comment.replies_num === 1
                                ? "View 1 reply"
                                : `View ${comment.replies_num} replies`}
                        </p>
                    ) : (
                        <p className="view" onClick={() => setReplies([])}>
                            {comment.replies_num === 1 ? "Hide reply" : "Hide replies"}
                        </p>
                    )}
                    {replies.length !== 0 &&
                        replies.map((reply, i) => {
                            return (
                                <div key={i} className="Comment">
                                    <div className="row">
                                        <ProfileInfo
                                            name={reply.user.name}
                                            username={reply.user.username}
                                            avatarSrc={reply.user.avatar_url}
                                        />
                                        <div className="col">
                                            <span className="date">
                                                {formatDate(new Date(reply.created_at))}
                                            </span>
                                            <Button
                                                deleteBtn
                                                iconSrc={DeleteIcon}
                                                onClick={() => deleteComentHandler(reply.id, true)}
                                                disabled={loading}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                    <div className="text">{reply.text}</div>
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
}
