import React, { useState, useEffect, createRef } from "react";
import Time from "./Time";
import ArrowIcon from "./../../assets/images/icons/drawer-arrow-grey.svg";
import CloseIcon from "./../../assets/images/icons/close_grey.svg";
import usePopup from "./../../customHooks/usePopup";

export default function WorkingHours({
    dayName = "",
    disabled = false,
    hours = {},
    onChangeHours,
}) {
    const [secondShift, setSecondShift] = useState(hours.from2 && true);
    const [clock, setClock] = usePopup(false);
    const clockRef = createRef();
    const [time, setTime] = useState("00:00");
    const [current, setCurrent] = useState("from");

    useEffect(() => {
        const handleClickOutsideClock = (event) => {
            // close clock if it cicked outside the clock
            if (clockRef.current && !clockRef.current.contains(event.target)) {
                setClock(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutsideClock);
        return () => document.removeEventListener("mousedown", handleClickOutsideClock);
    }, [clockRef, setClock]);

    const setClockHandler = (e) => {
        setCurrent(e.currentTarget.name);
        setTime(hours[e.currentTarget.name]);
        setClock(true);
    };

    const handleChange = (t) => {
        const newHours = {
            ...hours,
            [current]: t,
        };
        onChangeHours(newHours);
    };

    const deleteHours = (e) => {
        const newHours = {
            ...hours,
            [e.currentTarget.name]: "",
        };
        onChangeHours(newHours);
    };

    const secondShiftHandler = () => {
        if (secondShift) {
            setSecondShift(false);
            const newHours = {
                ...hours,
                from2: "",
                to2: "",
            };
            onChangeHours(newHours);
        } else {
            setSecondShift(true);
        }
    };

    return (
        <div className="Working-Hours">
            <div className="row">
                <span className="day">{dayName.charAt(0).toUpperCase() + dayName.slice(1)}</span>
                <div className="col">
                    <div className="input">
                        <span>{hours.from !== "" ? hours.from : "From"}</span>
                        {!disabled &&
                            (hours.from === "" ? (
                                <button
                                    type="button"
                                    name="from"
                                    onClick={(e) => setClockHandler(e)}
                                >
                                    <img src={ArrowIcon} alt="arrow icon" />
                                </button>
                            ) : (
                                <button type="button" name="from" onClick={(e) => deleteHours(e)}>
                                    <img className="close" src={CloseIcon} alt="close icon" />
                                </button>
                            ))}
                    </div>
                    {secondShift && (
                        <div className="input">
                            <span>{hours.from2 ? hours.from2 : "From"}</span>
                            {!disabled &&
                                (hours.from2 === "" ? (
                                    <button
                                        type="button"
                                        name="from2"
                                        onClick={(e) => setClockHandler(e)}
                                    >
                                        <img src={ArrowIcon} alt="arrow icon" />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        name="from2"
                                        onClick={(e) => deleteHours(e)}
                                    >
                                        <img className="close" src={CloseIcon} alt="close icon" />
                                    </button>
                                ))}
                        </div>
                    )}
                </div>
                <div className="dash">
                    <span>-</span>
                    {secondShift && <span>-</span>}
                </div>

                <div className="col">
                    <div className="input">
                        <span>{hours.to ? hours.to : "To"}</span>
                        {!disabled &&
                            (hours.to === "" ? (
                                <button type="button" name="to" onClick={(e) => setClockHandler(e)}>
                                    <img src={ArrowIcon} alt="arrow icon" />
                                </button>
                            ) : (
                                <button type="button" name="to" onClick={(e) => deleteHours(e)}>
                                    <img className="close" src={CloseIcon} alt="close icon" />
                                </button>
                            ))}
                    </div>
                    {secondShift && (
                        <div className="input">
                            <span>{hours.to2 ? hours.to2 : "To"}</span>
                            {!disabled &&
                                (hours.to2 === "" ? (
                                    <button
                                        type="button"
                                        name="to2"
                                        onClick={(e) => setClockHandler(e)}
                                    >
                                        <img src={ArrowIcon} alt="arrow icon" />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        name="to2"
                                        onClick={(e) => deleteHours(e)}
                                    >
                                        <img className="close" src={CloseIcon} alt="close icon" />
                                    </button>
                                ))}
                        </div>
                    )}
                </div>
                {!disabled && (
                    <button className="pls_btn" type="button" onClick={secondShiftHandler}>
                        <span>{secondShift ? "-" : "+"}</span>
                    </button>
                )}
            </div>
            {clock && (
                <Time
                    ref={clockRef}
                    time={time}
                    onChange={(time) => handleChange(time)}
                    closeTime={() => setClock(false)}
                />
            )}
        </div>
    );
}
