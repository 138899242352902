import React, { useEffect, useState } from "react";
import API from "../../APIs/sales";

export default function VoucherList({ selected, onVoucherSelected }) {
    const [vouchers, setVouchers] = useState([]);

    const getDeliveredCycles = async () => {
        try {
            const res = await API.getDeliveredCycles();
            setVouchers(res.data);
        } catch (error) {
            console.log("error -> ", error);
        }
    };

    const getPendingCycles = async () => {
        try {
            const res = await API.getPendingCycles();
            setVouchers(res.data);
        } catch (error) {
            console.log("error -> ", error);
        }
    };

    useEffect(() => {
        if (selected === "delivered") {
            getDeliveredCycles();
        } else if (selected === "pending") {
            getPendingCycles();
        }
    }, [selected]);

    return (
        <div className="Sales-Vouchers">
            {vouchers.length !== 0 ? (
                vouchers.map((voucher, i) => {
                    return (
                        <div
                            key={i}
                            className="voucher"
                            onClick={() => onVoucherSelected(voucher.voucher_id)}
                        >
                            <div className="image">
                                <img src={voucher.image} alt="voucher" />
                            </div>
                            <div className="info">
                                <p>{voucher.title}</p>
                                <p className="quantity">Quantity: {voucher.quantity}</p>
                            </div>
                            <div className="price">{voucher.totalPrice.toFixed(3)} BHD</div>
                        </div>
                    );
                })
            ) : (
                <span className="no-vocuhers">No vouchers yet</span>
            )}
        </div>
    );
}
