import api from "./APIlibrary";

const API = {
    login(params) {
        return api.post("auth/login", params);
    },
    logout() {
        return api.post("auth/logout");
    },
    resetPassword(params) {
        return api.post("auth/resetpassword", params);
    },
    verifyResetPassword(params) {
        return api.post("users/verifyResetPassword", params);
    },
    createNewPassword(params) {
        return api.post("auth/password", params);
    },
};

export default API;
