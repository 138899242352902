import { useState, useEffect } from "react";

const useDisplayableItem = (initial = false, itemRef = null, targetClasses = []) => {
    const [item, setItem] = useState(initial);

    useEffect(() => {
        // returns true if the element or one of its parents has the class "classname"
        function hasParentClasses(element, classArray) {
            if (!element || !classArray || !classArray.length) return false;
            if (!element.className)
                return element.parentNode && hasParentClasses(element.parentNode, classArray);
            const elementClasses = element.className.split(" ");
            for (let i = 0; i < classArray.length; i++) {
                if (elementClasses.includes(classArray[i])) return true;
            }
            return element.parentNode && hasParentClasses(element.parentNode, classArray);
        }

        const handleClickOutsideItem = (event) => {
            //do not close the Item if event target or its parents has one of the class from targetClasses array
            if (hasParentClasses(event.target, targetClasses)) {
                return;
            }
            // close Item if it is cicked outside the Item
            if (itemRef.current && !itemRef.current.contains(event.target)) {
                setItem(false);
            }
        };

        item && document.addEventListener("mousedown", handleClickOutsideItem);
        return () => document.removeEventListener("mousedown", handleClickOutsideItem);
    }, [itemRef, targetClasses, item]);

    return [item, (value) => setItem(value)];
};

export default useDisplayableItem;
