import React, { useState, useEffect } from "react";
import Button from "./../Button";
import Input from "./../Input";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import ErrorMsg from "../ErrorMsg";
import requsetErrorMessage from "./../../helpers/requsetErrorMessage";
import useFormInputs from "./../../customHooks/useFormInputs";
import paths from "../../constants/paths";
import API from "../../APIs/auth";
import { useForgottenPasswordStore } from "../../contexts/passwordContext";

export default function ResetPassword() {
    const store = useForgottenPasswordStore();
    const history = useHistory();
    const [passwordData, setPasswordData, errorMsg, setErrorMsg] = useFormInputs({
        newPassword: "",
        repeatPassword: "",
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });

    const handleResetPassword = async () => {
        if (passwordData.newPassword !== passwordData.repeatPassword) {
            setErrorMsg("Password must be repeated exactly");
            return;
        }
        setErrorMsg("");
        setLoading(true);
        try {
            await API.createNewPassword({
                hash: store.hash,
                username: store.username,
                password: passwordData.newPassword,
            });
            setLoading(false);
            history.push(paths.LOGIN);
        } catch (error) {
            setLoading(false);
            setErrorMsg(requsetErrorMessage(error && error));
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                handleSubmit(handleResetPassword);
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    });
    return (
        <form onSubmit={handleSubmit(handleResetPassword)} style={{ width: "100%" }}>
            <Input
                placeholder="New Password"
                type="password"
                name="newPassword"
                onChange={setPasswordData}
                errors={errors}
                ref={register({
                    required: "Required",
                    pattern: {
                        value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])\S*$/,
                        message: "Must contain 1 upper & lower letter, and 1 number",
                    },
                    minLength: {
                        value: 6,
                        message: "Must be at least 6 characters",
                    },
                })}
                theme="light"
                noLabel
            />
            <Input
                placeholder="Repeat Password"
                name="repeatPassword"
                type="password"
                onChange={setPasswordData}
                errors={errors}
                ref={register({
                    required: "Required",
                    pattern: {
                        value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])\S*$/,
                        message: "Must contain 1 upper & lower letter, and 1 number",
                    },
                    minLength: {
                        value: 6,
                        message: "Must be at least 6 characters",
                    },
                })}
                theme="light"
                noLabel
            />

            {errorMsg && <ErrorMsg message={errorMsg} />}
            <Button
                label="Reset"
                color="orange"
                type="submit"
                loading={loading}
                disabled={loading}
                style={{ marginTop: "30px" }}
            />
        </form>
    );
}
