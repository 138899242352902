import ProfileIcon from "./../../assets/images/icons/profile.svg";

export default function ProfileInfo({ name = "", username = "", avatarSrc = "", date = false }) {
    return (
        <div className="Profile-Info">
            <div className="Profile-Info-Avatar">
                {avatarSrc ? (
                    <img src={avatarSrc} alt="Profile Avatar" />
                ) : (
                    <img className="icon" src={ProfileIcon} alt="Profile Avatar" />
                )}
            </div>
            <div className="Profile-Info-Username">
                <span className="name">{name}</span>
                <span className="username">{!date ? username : date}</span>
            </div>
        </div>
    );
}
