import React, { useEffect, useState, forwardRef, useRef } from "react";
import ImagePlaceholder from "./ImagePlaceholder";
import usePopup from "../../customHooks/usePopup";
import Modal from "react-modal";
import Cropper from "react-easy-crop";
import { blue, filterImage, grayscale, green, invert, mirror, red, sepia } from "lena.js";
import { getCroppedImg, getVideoData } from "./utils";
import CloseIcon from "./../../assets/images/icons/close_orange.svg";
import Button from "./../Button";

const ImageCropper = forwardRef(
    (
        { blobCropped, isDisabled, isVisible = true, isVideoAllowed = false, mediaError = "" },
        ref,
    ) => {
        const videoRef = useRef();
        const [croppedBlob, setCroppedBlob] = useState();
        const [temporaryCroppedBlob, setTemporaryCroppedBlob] = useState();
        const [filePreview, setFilePreview] = useState("");
        const [fileName, setFileName] = useState("");
        const [isImageCropOpen, setIsImageCropOpen] = usePopup(false);
        const [cropPosition, setCropPosition] = useState({ x: 0, y: 0 });
        const [cropZoom, setCropZoom] = useState(1);
        const [cropAspect, setCropAspect] = useState(1);
        const [isApplyingFilter, setIsApplyingFilter] = useState(false);
        const [currentFilter, setCurrentFilter] = useState(null);
        const [isImageFile, setIsImageFile] = useState(false);
        const [videoData, setVideoData] = useState(null);
        const [videoOriginalDimensions, setVideoOriginalDimensions] = useState(null);
        const [videoDimensions, setVideoDimensions] = useState(null);
        const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(isVisible);
        const [videoWrapperWidth, setVideoWrapperWidth] = useState(
            window.innerWidth <= 350 ? 260 : window.innerWidth <= 991 ? 300 : 400,
        );

        useEffect(() => {
            if (isApplyingFilter) {
                const originalImage = document.getElementById("filter-source");
                const filteredImageCanvas = document.getElementById("filtered-image");

                filterImage(filteredImageCanvas, currentFilter, originalImage);
                const filteredImageURL = filteredImageCanvas.toDataURL("image/jpeg");

                setFilePreview(filteredImageURL);
                setIsApplyingFilter(false);
            }
        }, [isApplyingFilter, currentFilter]);

        useEffect(() => {
            if (videoData && videoData.ratio && videoData.ratio > 1) {
                setVideoDimensions({
                    widht: videoWrapperWidth * videoData.ratio,
                    height: videoWrapperWidth,
                });
            } else if (videoData && videoData.ratio && videoData.ratio < 1) {
                setVideoDimensions({
                    widht: videoWrapperWidth,
                    height: videoWrapperWidth / videoData.ratio,
                });
            }
        }, [videoData, videoWrapperWidth]);

        useEffect(() => {
            window.addEventListener("resize", getVideoWrapperWidth);
            return () => window.removeEventListener("resize", getVideoWrapperWidth);
        });

        const getVideoWrapperWidth = () => {
            if (window.innerWidth <= 350) {
                setVideoWrapperWidth(260);
            } else if (window.innerWidth <= 991) {
                setVideoWrapperWidth(300);
            } else {
                setVideoWrapperWidth(400);
            }
        };

        const onInputFileChange = async () => {
            if (ref.current.files[0]) {
                const fileType =
                    ref.current.files[0].type.indexOf("image") === -1 ? "video" : "image";
                const isImage = fileType === "image" ? true : false;

                setIsImageFile(isImage);

                const newFilePreview = URL.createObjectURL(ref.current.files[0]);
                setFilePreview(newFilePreview);

                setFileName(ref.current.files[0].name);
                setCropAspect(1);
                setCropZoom(1);
                setCropPosition({ x: 0, y: 0 });
                setIsImageCropOpen(true);
            }
        };

        const applyFilter = (imageFilter) => {
            setIsApplyingFilter(true);
            setCurrentFilter(() => imageFilter);
        };

        const onCropComplete = (croppedArea, croppedAreaPixels) => {
            if (isImageFile && videoData) {
                setVideoData(null);
            }
            if (isImageFile) {
                getCroppedImg(filePreview, croppedAreaPixels, fileName).then((blob) => {
                    setTemporaryCroppedBlob(blob);
                });
            } else {
                getVideoData(filePreview, croppedAreaPixels).then(
                    ({ videoData, videoOriginalDimensions }) => {
                        setVideoData(videoData);
                        setVideoOriginalDimensions(videoOriginalDimensions);
                        setTemporaryCroppedBlob(ref.current.files[0]);
                    },
                );
            }
        };

        const imageCursor = isDisabled ? "auto" : "pointer";
        const imageOnClick = isDisabled
            ? null
            : async () => {
                  setCroppedBlob(null);
                  blobCropped(null);
                  setIsPlaceholderVisible(true);
                  await ref.current.click();
              };

        const getVideoMarginTop = () => {
            let margin = (videoDimensions.height * videoData.y) / videoOriginalDimensions.height;
            return `-${margin}px`;
        };

        const getVideoMarginLeft = () => {
            let margin = (videoDimensions.widht * videoData.x) / videoOriginalDimensions.width;
            return `-${margin}px`;
        };

        return (
            <div className={"image-cropper"}>
                <input
                    type={"file"}
                    ref={ref}
                    style={{ display: "none" }}
                    onChange={onInputFileChange}
                    accept={isVideoAllowed ? "video/*,image/*" : "image/*"}
                />

                {!croppedBlob && isPlaceholderVisible && (
                    <ImagePlaceholder fileInputRef={ref} error={mediaError} />
                )}

                {croppedBlob && isImageFile && !isPlaceholderVisible && (
                    <img
                        style={{ cursor: imageCursor }}
                        src={URL.createObjectURL(croppedBlob)}
                        className={"cropped-image"}
                        alt="Preview"
                        onClick={imageOnClick}
                    />
                )}

                {croppedBlob && !isImageFile && !isPlaceholderVisible && (
                    <div
                        className="cropped-video"
                        style={{
                            width: `${videoWrapperWidth}px`,
                            height: `${videoWrapperWidth}px`,
                        }}
                    >
                        <video
                            id="video"
                            src={URL.createObjectURL(croppedBlob)}
                            onClick={imageOnClick}
                            autoPlay
                            muted
                            loop
                            style={{
                                cursor: imageCursor,
                                marginLeft: videoData && videoData.x ? getVideoMarginLeft() : 0,
                                marginTop: videoData && videoData.y ? getVideoMarginTop() : 0,
                                width: videoData && videoData.ratio > 1 ? "unset" : "100%",
                                height: videoData && videoData.ratio < 1 ? "unset" : "100%",
                            }}
                            ref={videoRef}
                        />
                    </div>
                )}

                {mediaError && !croppedBlob && (
                    <div className="image-error">
                        <span>Required</span>
                    </div>
                )}

                <Modal
                    isOpen={isImageCropOpen}
                    className="modal-image-crop"
                    overlayClassName="modal-image-crop-overlay"
                >
                    <div className="modal-header">
                        <p className="title">Eidt {isImageFile ? "Photo" : "Video"}</p>
                        <button
                            disabled={isApplyingFilter}
                            className="close-button"
                            onClick={() => {
                                ref.current.value = null;
                                setIsImageCropOpen(false);
                                setIsPlaceholderVisible(true);
                            }}
                        >
                            <img src={CloseIcon} alt="close icon" />
                        </button>
                    </div>

                    <div className={"crop-wrapper"}>
                        <Cropper
                            image={isImageFile ? filePreview : null}
                            video={isImageFile ? null : filePreview}
                            aspect={cropAspect}
                            crop={cropPosition}
                            onCropChange={(crop) => {
                                setCropPosition(crop);
                            }}
                            zoom={cropZoom}
                            onZoomChange={isImageFile ? (zoom) => setCropZoom(zoom) : null}
                            onCropComplete={onCropComplete}
                        />
                    </div>
                    <div
                        style={{
                            display: isImageFile ? "flex" : "none",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={filePreview}
                            id="filter-source"
                            alt=""
                            style={{ display: "none" }}
                        />
                        <canvas id="filtered-image" style={{ display: "none" }}></canvas>
                        <p
                            style={{
                                fontSize: "14px",
                                margin: "15px 0 10px",
                            }}
                        >
                            FILTERS
                        </p>
                        <div className="image-filters">
                            <button
                                disabled={isApplyingFilter}
                                onClick={() => applyFilter(grayscale)}
                            >
                                Grayscale
                            </button>
                            <button disabled={isApplyingFilter} onClick={() => applyFilter(mirror)}>
                                Mirror
                            </button>
                            <button disabled={isApplyingFilter} onClick={() => applyFilter(sepia)}>
                                Sepia
                            </button>
                            <button disabled={isApplyingFilter} onClick={() => applyFilter(invert)}>
                                Invert
                            </button>
                            <button disabled={isApplyingFilter} onClick={() => applyFilter(red)}>
                                Red
                            </button>
                            <button disabled={isApplyingFilter} onClick={() => applyFilter(green)}>
                                Green
                            </button>
                            <button disabled={isApplyingFilter} onClick={() => applyFilter(blue)}>
                                Blue
                            </button>
                            <button
                                className="clear"
                                disabled={isApplyingFilter}
                                onClick={() => {
                                    const newFilePreview = URL.createObjectURL(
                                        ref.current.files[0],
                                    );
                                    setFilePreview(newFilePreview);
                                }}
                            >
                                Clear filters
                            </button>
                        </div>
                    </div>

                    <Button
                        disabled={isApplyingFilter}
                        onClick={() => {
                            ref.current.value = null;
                            setIsImageCropOpen(false);
                            setCroppedBlob(temporaryCroppedBlob);
                            blobCropped(temporaryCroppedBlob, videoData);
                            setIsPlaceholderVisible(false);
                        }}
                        color="green"
                        label="Done"
                        maxWidth="300px"
                    />
                    {isApplyingFilter && <div>Applying filter...</div>}
                </Modal>
            </div>
        );
    },
);

export default ImageCropper;
