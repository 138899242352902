import React, { createRef, useState, useEffect } from "react";
import Page from "./../components/Page";
import Paper from "./../components/Paper";
import VoucherIcon from "./../assets/images/icons/voucher.svg";
import Input from "./../components/Input";
import Button from "./../components/Button";
import WarningMsg from "./../components/warningMsg";
import DatePicker from "./../components/DatePicker";
import { useForm } from "react-hook-form";
import API from "../APIs/newVoucher";
import profileAPI from "../APIs/profile";
import { useHistory } from "react-router";
import SideDrawer from "./../components/SideDrawer";
import VoucherView from "./../components/VoucherView";
import useDrawer from "../customHooks/useDisplayableItem";
import useFormInputs from "./../customHooks/useFormInputs";
import ErrorMsg from "../components/ErrorMsg";
import requsetErrorMessage from "./../helpers/requsetErrorMessage";
import ImageCropper from "../components/ImageCropper";
import paths from "../constants/paths";

export default function NewVoucher() {
    const history = useHistory();
    const descriptionRef = createRef();
    const drawerRef = createRef();
    const [newVoucher, setNewVoucher, errorMsg, setErrorMsg] = useFormInputs({
        image: "",
        title: "",
        description: "",
        price: 0,
        discount: 0,
        quantity: 0,
        available_till: "",
        valid_till: "",
    });
    const [dateRequired, setDateRequired] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDrawer, setShowDrawer] = useDrawer(false, drawerRef, []);
    const [isPreview, setIsPrview] = useState(false);
    const [totalPrice, setTotalPrice] = useState("0.000");
    const [VAT, setVAT] = useState(0);
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
    const dayAfterTmrw = new Date(Date.now() + 2 * (24 * 60 * 60 * 1000));
    const tomorrowInAYear = new Date(Date.now() + 366 * 24 * 60 * 60 * 1000);
    const dayAfterTmrwInAYear = new Date(Date.now() + 367 * 24 * 60 * 60 * 1000);

    const [croppedBlob, setCroppedBlob] = useState(null);
    const fileImageRef = createRef();

    useEffect(() => {
        const getUsersVAT = async () => {
            try {
                const res = await profileAPI.getProfile();
                setVAT(res.data.VAT);
            } catch (error) {
                console.log("error -> ", error);
            }
        };
        getUsersVAT();
    }, []);

    useEffect(() => {
        setTotalPrice(
            VAT === 0
                ? ((1 - newVoucher.discount / 100) * newVoucher.price).toFixed(3)
                : ((1 + VAT / 100) * (1 - newVoucher.discount / 100) * newVoucher.price).toFixed(3),
        );
    }, [newVoucher.price, newVoucher.discount, VAT]);

    const handleDateChange = (name, date) => {
        errorMsg && setErrorMsg("");
        name === "valid_till" && setDateRequired(false);
        setNewVoucher({
            ...newVoucher,
            [name]: date && date.toISOString(),
        });
    };

    const handleNewVoucher = async () => {
        if (!newVoucher.valid_till) {
            setDateRequired(true);
            return;
        }
        if (new Date(newVoucher.valid_till) <= new Date(newVoucher.available_till)) {
            setErrorMsg("Usage End Date must be later than Listing End Date");
            return;
        }
        if (isPreview) {
            setShowDrawer(true);
            return;
        }
        setLoading(true);
        try {
            const signatureAjax = await fetch(`/webapi/uploadsignature?timestamp=${Date.now()}`, {
                method: "POST",
            });
            const { api_key, signature, timestamp, upload_url } = await signatureAjax.json();
            const reader = new FileReader();
            reader.onloadend = async function () {
                const base64data = reader.result;

                const formData = new FormData();
                formData.append("file", base64data);
                formData.append("api_key", api_key);
                formData.append("timestamp", timestamp);
                formData.append("signature", signature);
                const uploadAjax = await fetch(upload_url, { method: "POST", body: formData });
                const { secure_url } = await uploadAjax.json();

                try {
                    const postParams = { ...newVoucher, ...{ image: secure_url } };

                    await API.createVoucher(postParams);
                    history.push(paths.VOUCHERS);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    setErrorMsg(requsetErrorMessage(error && error));
                }
            };
            reader.readAsDataURL(croppedBlob);
        } catch (error) {
            setLoading(false);
            setErrorMsg("Please upload an image for your voucher");
        }
    };

    return (
        <Page>
            <Paper title="Create New Voucher" icon={VoucherIcon} iconBackground="orange">
                <form className="New-Voucher" onSubmit={handleSubmit(handleNewVoucher)}>
                    <div className="New-Voucher-Col">
                        <ImageCropper
                            isDisabled={loading}
                            ref={fileImageRef}
                            blobCropped={(blob) => {
                                setCroppedBlob(blob);
                            }}
                            mediaError={errors.media}
                        />
                        {!croppedBlob && (
                            <input
                                className="hidden-input"
                                name="media"
                                type="text"
                                ref={register({
                                    required: true,
                                })}
                            />
                        )}
                    </div>
                    <div className="New-Voucher-Col">
                        <Input
                            label="Title"
                            type="text"
                            name="title"
                            onChange={setNewVoucher}
                            maxLenght="20"
                            errors={errors}
                            ref={register({
                                required: "Required",
                                minLength: {
                                    value: 3,
                                    message: "Title must be from 3 to 20 characters",
                                },
                            })}
                            disabled={loading}
                        />
                        <Input
                            label="Description"
                            type="text"
                            name="description"
                            isTextarea
                            maxLenght="120"
                            ref={descriptionRef}
                            hasCounter
                            onChange={setNewVoucher}
                            disabled={loading}
                        />
                        <div className="price-content">
                            <div className="price">
                                <Input
                                    label="Price BHD"
                                    type="number"
                                    name="price"
                                    min="0"
                                    max="1000"
                                    onChange={setNewVoucher}
                                    errors={errors}
                                    ref={register({
                                        required: "Required",
                                        min: {
                                            value: 3,
                                            message: "Price must be from 3 to 1000",
                                        },
                                        max: {
                                            value: 3000,
                                            message: "Price must be from 3 to 1000",
                                        },
                                    })}
                                    disabled={loading}
                                />
                                <Input
                                    label="Discount %"
                                    type="number"
                                    min="0"
                                    max="99"
                                    name="discount"
                                    onChange={setNewVoucher}
                                    errors={errors}
                                    ref={register({
                                        required: "Required",
                                        min: {
                                            value: 0,
                                            message: "Discount must be from 0 to 99",
                                        },
                                        max: {
                                            value: 99,
                                            message: "Discount must be from 0 to 99",
                                        },
                                    })}
                                    disabled={loading}
                                />
                            </div>
                            <div className="price-total">
                                <p className="total">Total Price</p>
                                <p className="number">
                                    {totalPrice} <br /> BHD
                                </p>
                                <p className="vat">{VAT !== 0 && VAT + "% VAT included"}</p>
                            </div>
                        </div>
                        <Input
                            label="Number of Vouchers"
                            name="quantity"
                            type="number"
                            min="1"
                            max="5"
                            onChange={setNewVoucher}
                            errors={errors}
                            ref={register({
                                required: "Required",
                                min: {
                                    value: 1,
                                    message: "Quantity must be less than or equal to 5",
                                },
                                max: {
                                    value: 5,
                                    message: "Quantity must be less than or equal to 5",
                                },
                            })}
                            disabled={loading}
                        />
                        <DatePicker
                            label="Listing End Date"
                            selectText="Select Voucher Listing End Date"
                            name="available_till"
                            onChangeEvent={(name, date) => handleDateChange(name, date)}
                            disabled={loading}
                            minDate={tomorrow}
                            maxDate={tomorrowInAYear}
                        />
                        <DatePicker
                            label="Listing Usage Date"
                            selectText="Select Voucher Usage End Date"
                            name="valid_till"
                            onChangeEvent={(e, date) => handleDateChange(e, date)}
                            error={dateRequired}
                            disabled={loading}
                            minDate={dayAfterTmrw}
                            maxDate={dayAfterTmrwInAYear}
                        />
                        <WarningMsg
                            text="Important Information"
                            additional="Be aware that once the voucher is published no information can be changed."
                        />
                        {errorMsg && <ErrorMsg message={errorMsg} color="orange" />}
                        <Button
                            type="submit"
                            label="Voucher Preview"
                            disabled={showDrawer || loading}
                            onClick={() => setIsPrview(true)}
                        />
                        <Button
                            type="submit"
                            color="green"
                            label="Publish"
                            disabled={loading}
                            loading={loading}
                            onClick={() => setIsPrview(false)}
                        />
                    </div>
                </form>
            </Paper>
            <SideDrawer
                ref={drawerRef}
                visible={showDrawer}
                hideDrawer={() => setShowDrawer(false)}
            >
                <VoucherView
                    voucher={newVoucher}
                    isPreview
                    totalPrice={totalPrice}
                    blob={croppedBlob}
                />
            </SideDrawer>
        </Page>
    );
}
