function getX(offset, width) {
    const x = offset.x ? Math.floor((width * offset.ratio - width) * (offset.x / 100)) : 0;
    return x;
}

function getY(offset, width) {
    const y = offset.y ? Math.floor((width / offset.ratio - width) * (offset.y / 100)) : 0;
    return y;
}

export { getX, getY };
