import ProfileInfo from "./../ProfileInfo";
import ArrowIcon from "./../../assets/images/icons/drawer-arrow.svg";
import { formatDate } from "./../../helpers/formatDate";

export default function Purchases({ list = [], visible = false, onClickEvent }) {
    return (
        <div className={`Purchases ${visible ? "show" : ""} `}>
            <div className="Purchases-Header">
                <span>Purchases:</span>
                <img src={ArrowIcon} alt="arrow icon" onClick={onClickEvent} />
            </div>
            {list.map((purchase, i) => {
                return (
                    <div key={i} className="row">
                        <ProfileInfo
                            key={i}
                            name={purchase.user.name}
                            username={purchase.user.username}
                            avatarSrc={purchase.user.avatar_url}
                            date={formatDate(new Date(purchase.created_at))}
                        />
                        <div className="info">
                            <div className="num-box">
                                <span>{purchase.purchased_num}</span>
                                <span>Purchased</span>
                            </div>
                            <div className="num-box">
                                <span>{purchase.purchased_num}</span>
                                <span>Used</span>
                            </div>
                        </div>
                    </div>
                );
            })}
            <hr className="divider"></hr>
        </div>
    );
}
