import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Home from "./pages/home";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Vouchers from "./pages/Vouchers";
import Posts from "./pages/Posts";
import NewVoucher from "./pages/NewVoucher";
import NewPost from "./pages/NewPost";
import Scan from "./pages/Scan";
import Terms from "./pages/Terms";
import Footer from "./components/Footer";
import Profile from "./pages/Profile";
import usePopup from "./customHooks/usePopup";
import Sales from "./pages/Sales";
import BankAccountDetails from "./pages/BankAccount";
import paths from "./constants/paths";
import { useUserStore } from "./contexts/userContext";

export default function App() {
    const [showMenu, setShowMenu] = usePopup(false);
    const pathname = useLocation().pathname;
    const { user } = useUserStore();

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(user));
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <main>
            <Header onClickEvent={() => setShowMenu(!showMenu)} showMenu={showMenu} />
            <Navigation showMenu={showMenu} onClickEvent={() => showMenu && setShowMenu(false)} />
            <Switch>
                <Route exact path={paths.HOME} component={Home} />
                <Route exact path={paths.LOGIN} component={Login} />
                <Route exact path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route exact path={paths.VOUCHERS} component={Vouchers} />
                <Route exact path={paths.POSTS} component={Posts} />
                <Route exact path={paths.NEW_VOUCHER} component={NewVoucher} />
                <Route exact path={paths.NEW_POST} component={NewPost} />
                <Route exact path={paths.SCAN} component={Scan} />
                <Route exact path={paths.TERMS} component={Terms} />
                <Route exact path={paths.PROFILE} component={Profile} />
                <Route exact path={paths.SALES} component={Sales} />
                <Route exact path={paths.BANK_ACCOUNT} component={BankAccountDetails} />
            </Switch>
            <Footer />
        </main>
    );
}
