import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "./../../assets/images/logo/proxfit_white_simbol.svg";
import HamburgerIcon from "./../../assets/images/icons/hamburger.svg";
import HamburgeOrangeIcon from "./../../assets/images/icons/hamburger_orange.svg";
import navigationConfig from "../../configs/navigation";
import useScrolledToTop from "./../../customHooks/useScrolledToTop";

const isIgonred = (pathname) => {
    return navigationConfig.ignoredOnPages.find((page) => pathname.includes(page));
};

export default function Header({ onClickEvent, showMenu = false }) {
    const location = useLocation();
    const [hideNav, setHideNav] = useState(isIgonred(location.pathname));
    const isScrolledToTop = useScrolledToTop();

    useEffect(() => {
        setHideNav(isIgonred(location.pathname));
    }, [location]);

    return (
        <div className={`Header ${isScrolledToTop ? "" : "hide"}`}>
            <div className="Header-Inner">
                <div className="Header-Inner-Logo">
                    <img src={Logo} alt="Small Proxfit Logo" />
                </div>
                <div className="Header-Inner-Headline">Proxfit Business</div>
                {hideNav ? null : (
                    <button className="btn-toggle" onClick={onClickEvent} aria-label="Hamburger">
                        <img
                            src={showMenu ? HamburgeOrangeIcon : HamburgerIcon}
                            alt="hamburger icon"
                        />
                    </button>
                )}
            </div>
        </div>
    );
}
