import React from "react";
import Item from "./Item";
import NoResults from "./../NoResults";

export default function PostList({ setPostForDrawer, posts = [], noPosts }) {
    return (
        <div className="Post-List">
            <div className="Post-List-Content">
                {posts &&
                    posts.length !== 0 &&
                    posts.map((post, i) => {
                        return (
                            <Item
                                key={i}
                                postName={post.title}
                                mediaSrc={post.media_url}
                                onClickEvent={() => setPostForDrawer(post)}
                                isImage={post.is_image}
                                mediaOffset={post.media_offset}
                            />
                        );
                    })}
            </div>
            {posts && posts.length === 0 && noPosts ? (
                <NoResults message="Your posts will be here..." />
            ) : null}
        </div>
    );
}
