import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/style.scss";
import { UserProvider } from "./contexts/userContext";
import Modal from "react-modal";

Modal.setAppElement("#root");

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <UserProvider>
                <App />
            </UserProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
