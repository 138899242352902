export default function Item({ voucher, onClickEvent }) {
    return (
        <div className="Item" onClick={onClickEvent}>
            <div className="Item-Image">
                <img src={voucher.image} alt="voucher" />
                <div className="Item-Image-Discount">
                    <span>{voucher.discount}%</span>
                </div>
                <div
                    className={[
                        "Item-Image-Bottom",
                        voucher.status === "active" ? "active" : "",
                        voucher.status === "unlisted" || voucher.status === "expired"
                            ? "expired"
                            : "",
                        voucher.status === "cancelled" ? "cancelled" : "",
                        voucher.status === "sold" ? "sold" : "",
                    ].join(" ")}
                >
                    <p className="label">
                        {voucher.status === "active"
                            ? `${voucher.in_stock} in stock`
                            : voucher.status === "unlisted" || voucher.status === "expired"
                            ? "Unlisted"
                            : voucher.status === "cancelled"
                            ? "Cancelled"
                            : voucher.status === "sold"
                            ? "Sold-out"
                            : ""}
                    </p>
                </div>
            </div>
            <div className="Item-Info">
                <p className="name">{voucher.title}</p>
                <div className="price">
                    <span className="price-original">{voucher.price}</span>
                    <span className="price-discount">{voucher.totalPrice.toFixed(3)} BHD</span>
                </div>
            </div>
        </div>
    );
}
