import InfoIcon from "./../../assets/images/icons/about.svg";

export default function WarningMsg({ text = "", additional = "" }) {
    return (
        <div className="Warning-Msg">
            <p>
                {text && <img src={InfoIcon} alt="Info Icon" />}
                {text}
            </p>
            {additional && <div className="Warning-Msg-More">{additional}</div>}
        </div>
    );
}
