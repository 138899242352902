import React, { useState, useEffect } from "react";
import StarIcon from "./../../assets/images/icons/star-grey.svg";

export default function Rate({ rate, count, totalRatings }) {
    const [removeDefault, setRemovedDefault] = useState(false);
    const percentage = count !== 0 ? (100 * count) / totalRatings : 0;
    useEffect(() => {
        setTimeout(() => setRemovedDefault(true), 500);
    }, []);
    return (
        <div className="Rate">
            <span className="mark">{rate}</span>
            <img src={StarIcon} alt="star icon" />
            <div className="bar">
                <span
                    className={`percentage ${removeDefault ? "" : "default"}`}
                    style={removeDefault ? { width: percentage } : {}}
                ></span>
            </div>
        </div>
    );
}
