import React, { useState, useEffect, useMemo } from "react";
import API from "../../APIs/profile";
import StarIcon from "./../../assets/images/icons/star.svg";
import Review from "./Review";
import Rate from "./Rate";

const getTotalRatings = (data) => {
    let total = 0;
    data.forEach((el) => {
        return (total = total + el.count);
    });
    return total;
};

export default function Reviews() {
    const defaultRates = useMemo(
        () => [
            { rate: 5, count: 0 },
            { rate: 4, count: 0 },
            { rate: 3, count: 0 },
            { rate: 2, count: 0 },
            { rate: 1, count: 0 },
        ],
        [],
    );
    const [averageRating, setAverageRating] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [totalRatings, setTotalRatings] = useState(null);
    const [countsPerRate, setCountsPerRate] = useState([]);
    const [rates, setRates] = useState(defaultRates);

    useEffect(() => {
        const getReviews = async () => {
            try {
                const res = await API.getReviews();
                setAverageRating(res.data.averageRating.toFixed(1));
                setReviews(res.data.reviews);
                setTotalRatings(getTotalRatings(res.data.countsPerRate));
                setCountsPerRate(res.data.countsPerRate);
            } catch (error) {
                console.log("error -> ", error);
            }
        };
        getReviews();
    }, []);

    useEffect(() => {
        const getRates = (resDataRates) => {
            let newRates = [];
            defaultRates.forEach((el) => {
                const rated = resDataRates.find((rate) => rate.rate === el.rate);
                if (rated) {
                    newRates.push({
                        rate: rated.rate,
                        count: rated.count,
                    });
                } else {
                    newRates.push(el);
                }
            });

            return newRates;
        };
        setRates(getRates(countsPerRate));
    }, [countsPerRate, defaultRates]);

    return (
        <div className="Reviews">
            <p className="title">Ratings and Reviews</p>
            <div className="Ratings">
                <div className="Ratings-Average-Rate">
                    {averageRating !== null && (
                        <>
                            <div>
                                <span className="mark">{averageRating && averageRating}</span>
                                <img src={StarIcon} alt="star icon" />
                            </div>
                            <p>out of 5</p>
                        </>
                    )}
                </div>
                <div className="Ratings-All-Rates">
                    {rates.map((rate, i) => {
                        return (
                            <Rate
                                key={i}
                                rate={rate.rate}
                                count={rate.count}
                                totalRatings={totalRatings}
                            />
                        );
                    })}
                    <div className="based">
                        {totalRatings !== 0 && totalRatings !== null && (
                            <div>
                                based on{" "}
                                {totalRatings === 1
                                    ? `${totalRatings} rating`
                                    : `${totalRatings} ratings`}
                            </div>
                        )}
                        {totalRatings === 0 && <div>No ratings yet</div>}
                    </div>
                </div>
            </div>
            {reviews.length !== 0 &&
                reviews.map((review, i) => {
                    return <Review key={i} review={review} />;
                })}
        </div>
    );
}
