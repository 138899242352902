import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Picker = forwardRef(
    (
        {
            label = "",
            selectText = "",
            error = false,
            name = "",
            onChangeEvent,
            disabled = false,
            minDate,
            maxDate,
        },
        ref,
    ) => {
        const [startDate, setStartDate] = useState("");

        const changeHandler = (date) => {
            onChangeEvent(name, date);
            setStartDate(date);
        };
        return (
            <div className="Date-Picker">
                <label>{label}</label>
                <DatePicker
                    ref={ref}
                    disabled={disabled}
                    name={name}
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholderText={selectText}
                    selected={startDate}
                    onChange={(date, e) => changeHandler(date, e)}
                    autoComplete="off"
                />
                {error && <span className="error-msg">Required</span>}
            </div>
        );
    },
);

export default Picker;
