function formatDate(date) {
    var day = date.getDate();
    var monthIndex = date.getMonth() + 1;
    var year = date.getFullYear();

    day = day < 10 ? "0" + day : day;
    monthIndex = monthIndex < 10 ? "0" + monthIndex : monthIndex;

    return day + "." + monthIndex + "." + year;
}

function formatDateAndTime(date) {
    var day = date.getDate();
    var monthIndex = date.getMonth() + 1;
    var year = date.getFullYear();
    var hours = date.getHours();
    var min = date.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    min = min < 10 ? "0" + min : min;
    day = day < 10 ? "0" + day : day;
    monthIndex = monthIndex < 10 ? "0" + monthIndex : monthIndex;

    return day + "." + monthIndex + "." + year + "\n" + hours + ":" + min;
}

function formatTime(date) {
    var hours = date.getHours();
    var min = date.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    min = min < 10 ? "0" + min : min;

    return hours + ":" + min;
}

export { formatDate, formatDateAndTime, formatTime };
