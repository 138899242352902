import api from "./APIlibrary";

const API = {
    getVouchers(params) {
        return api.get("/vouchers", params);
    },
    getVoucher(id, params) {
        return api.get(`/vouchers/${id}`, params);
    },
    getVoucherPurchases(id, params) {
        return api.get(`/vouchers/${id}/purchases`, params);
    },
    cancelVoucher(id) {
        return api.put(`/vouchers/${id}/cancel`);
    },
};

export default API;
