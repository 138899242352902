import ProfileIcon from "./../../assets/images/icons/profile.svg";
import CloseIcon from "./../../assets/images/icons/close_black.svg";
import { usePostFormStore, usePostFormDispatch } from "./../../contexts/postFormContext";

export default function Tagged() {
    const { tagged } = usePostFormStore();
    const dispatch = usePostFormDispatch();
    const removeTag = (tag) => {
        let newTags = [...tagged];
        for (var i = 0; i < newTags.length; i++) {
            var user = newTags[i];
            if (user.id === tag.id) {
                newTags.splice(i, 1);
                let tagsID = newTags.map((tag) => {
                    return tag.id;
                });
                dispatch({
                    type: "TAGGED",
                    tagged: [...newTags],
                    tagsID: [...tagsID],
                });
            }
        }
    };
    return (
        <div className="avatars">
            {tagged.map((tag, i) => {
                return (
                    <div key={i} className="avatar" onClick={() => removeTag(tag)}>
                        {tag.avatar_url ? (
                            <img src={tag.avatar_url} alt="avatar" />
                        ) : (
                            <img className="icon" src={ProfileIcon} alt="avatar" />
                        )}
                        <div className="remove">
                            <img src={CloseIcon} alt="close icon" />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
