import ProfileInfo from "./../ProfileInfo";
import ArrowIcon from "./../../assets/images/icons/drawer-arrow.svg";

export default function LikesList({ list = [], visible = false, onClickEvent }) {
    return (
        <div className={`Likes-List ${visible ? "show" : ""} `}>
            <div className="Likes-List-Header">
                <span>Likes:</span>
                <img src={ArrowIcon} alt="arrow icon" onClick={onClickEvent} />
            </div>
            {list.map((like, i) => {
                return (
                    <ProfileInfo
                        key={i}
                        name={like.user.name}
                        username={like.user.username}
                        avatarSrc={like.user.avatar_url}
                    />
                );
            })}
            <hr className="divider"></hr>
        </div>
    );
}
