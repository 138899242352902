import { useState, useEffect } from "react";
import { checkIfLogged } from "./../helpers/checkIfLogged";

const useLoginCheck = () => {
    const cookies = document.cookie;
    const [userLoggedIn, setUserLoggedIn] = useState(checkIfLogged() ? true : false);

    useEffect(() => {
        setUserLoggedIn(checkIfLogged() ? true : false);
    }, [cookies]);

    return userLoggedIn;
};

export default useLoginCheck;
