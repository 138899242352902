import React, { useState, useEffect } from "react";
import Button from "./../Button";
import Input from "./../Input";
import LoginIcon from "./../../assets/images/icons/login.svg";
import BackIcon from "./../../assets/images/icons/arrow_left.svg";
import { useHistory } from "react-router";
import API from "../../APIs/auth";
import { useForm } from "react-hook-form";
import ErrorMsg from "../ErrorMsg";
import { useUserDispatch } from "../../contexts/userContext";
import requsetErrorMessage from "./../../helpers/requsetErrorMessage";
import useFormInputs from "./../../customHooks/useFormInputs";
import paths from "../../constants/paths";

export default function LoginForm() {
    const history = useHistory();
    const [loginData, setLoginData, errorMsg, setErrorMsg] = useFormInputs({
        username: "",
        password: "",
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const dispatch = useUserDispatch();

    const handleLogin = async () => {
        setErrorMsg("");
        setLoading(true);
        try {
            const res = await API.login(loginData);
            dispatch({
                type: "CREATE_USER",
                value: {
                    id: res.data.id,
                    username: res.data.username,
                    name: res.data.name,
                    avatar: res.data.avatar_url,
                },
            });
            history.push(paths.HOME);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErrorMsg(requsetErrorMessage(error && error));
            console.log("error => " + error);
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                handleSubmit(handleLogin);
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    });
    return (
        <form onSubmit={handleSubmit(handleLogin)} style={{ width: "100%" }}>
            <Input
                placeholder="Username"
                name="username"
                onChange={setLoginData}
                errors={errors}
                ref={register({ required: "Required" })}
                theme="light"
                noLabel
            />
            <Input
                placeholder="Password"
                name="password"
                type="password"
                onChange={setLoginData}
                errors={errors}
                ref={register({ required: "Required" })}
                theme="light"
                noLabel
            />
            {errorMsg && <ErrorMsg message={errorMsg} />}
            <Button
                label="Login"
                color="orange"
                type="submit"
                iconSrc={LoginIcon}
                loading={loading}
                disabled={loading}
            />
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                }}
            >
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(paths.HOME)}
                    title="Back"
                >
                    <img src={BackIcon} alt="arrow icon" style={{ width: "20px" }} />
                </span>
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(paths.FORGOT_PASSWORD)}
                >
                    Forgot Password?
                </span>
            </div>
        </form>
    );
}
