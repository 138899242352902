import VideoIcon from "./../../assets/images/icons/video.svg";
import { getX, getY } from "./../../helpers/getVideoOffsets";

export default function Item({ onClickEvent, isImage = false, mediaSrc, mediaOffset }) {
    const cloudinary_path = mediaSrc.split("upload/")[1];

    return (
        <div className="Item" onClick={onClickEvent}>
            <div className="Item-Image">
                {!isImage && (
                    <div className="Item-Image-Icon">
                        <img src={VideoIcon} alt="Video Icon" />
                    </div>
                )}
                {isImage ? (
                    <img src={mediaSrc} alt="media" />
                ) : (
                    mediaOffset && (
                        <video>
                            <source
                                src={`https://res.cloudinary.com/proxfit/video/upload/w_720,h_720,c_fill,x_${getX(
                                    mediaOffset,
                                    720,
                                )},y_${getY(mediaOffset, 720)},q_auto:eco/${cloudinary_path}`}
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    )
                )}
            </div>
        </div>
    );
}
