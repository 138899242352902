export default function DotsProgress({ numOfSteps, current }) {
    const steps = [];
    for (let i = 1; i <= numOfSteps; i++) {
        steps.push(i);
    }
    return (
        <div className="Progress-dots">
            {steps.map((step) => (
                <span key={step} className={`step ${step === current ? "current" : " "}`}></span>
            ))}
        </div>
    );
}
