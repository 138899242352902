function checkIfLogged() {
    const cookiesArray = document.cookie.split("; ");
    const isLoggedIn = cookiesArray.find((cookie) => {
        if (cookie === "lgd_in=true") {
            return true;
        } else {
            return false;
        }
    });
    return isLoggedIn;
}

export { checkIfLogged };
