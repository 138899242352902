import ProfileInfo from "./../ProfileInfo";
import ArrowIcon from "./../../assets/images/icons/drawer-arrow.svg";

export default function TaggedList({ list = [], visible = false, onClickEvent }) {
    return (
        <div className={`Tagged-List ${visible ? "show" : ""} `}>
            <div className="Tagged-List-Header">
                <span>Tagged:</span>
                <img src={ArrowIcon} alt="arrow icon" onClick={onClickEvent} />
            </div>
            {list.map((tag, i) => {
                return (
                    <ProfileInfo
                        key={i}
                        name={tag.name}
                        username={tag.username}
                        avatarSrc={tag.avatar_url}
                    />
                );
            })}
            <hr className="divider"></hr>
        </div>
    );
}
