import React, { useState, useEffect, forwardRef } from "react";
import ArrowIcon from "./../../assets/images/icons/drawer-arrow.svg";

const SideDrawer = forwardRef(({ visible = false, hideDrawer, children }, ref) => {
    const [show, setShow] = useState(visible);

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    return (
        <div ref={ref} className={`Side-Drawer ${show ? "show" : ""}`}>
            <button
                className="Side-Drawer-Close"
                onClick={() => {
                    hideDrawer();
                    setShow(false);
                }}
            >
                <img src={ArrowIcon} alt="simple icon" />
            </button>
            <div className="Side-Drawer-Content">{children}</div>
        </div>
    );
});

export default SideDrawer;
