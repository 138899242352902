import React from "react";
import Page from "./../../components/Page";
import Box from "./../../components/Box";
import ExploreImg from "./../../assets/images/news/explore.png";
import PromoteImg from "./../../assets/images/news/promote.png";
import Monetize from "./../../assets/images/news/monetize.png";

export default function Home() {
    return (
        <Page>
            <Box
                title="Pin your business on a map"
                text="Become visible to customers in your proximity. Help them to find and contact you easier."
                animationName="fadeInLeft"
                images={[
                    {
                        src: ExploreImg,
                        alt: "Explore",
                        style: {
                            width: "100%",
                        },
                    },
                ]}
            />
            <Box
                isRowReverse
                title="Promote your Content"
                text="Be noticed on the high concentration network through promotions."
                animationName="fadeInRight"
                images={[
                    {
                        src: PromoteImg,
                        alt: "Promote",
                        style: {
                            width: "100%",
                        },
                    },
                ]}
            />
            <Box
                title="Monetize your business"
                text="Sell your services and products through a unique digital voucher system."
                animationName="fadeInLeft"
                images={[
                    {
                        src: Monetize,
                        alt: "Monetize",
                        style: {
                            width: "100%",
                        },
                    },
                ]}
            />
        </Page>
    );
}
