import React, { useState, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import API from "../../APIs/auth";
import Button from "../Button";
import {
    useForgottenPasswordStore,
    useForgottenPasswordDispatch,
} from "../../contexts/passwordContext";
import ErrorMsg from "../ErrorMsg";
import useFormInputs from "../../customHooks/useFormInputs";
import requsetErrorMessage from "./../../helpers/requsetErrorMessage";

export default function CodeInput({ onNext }) {
    const [loading, setLoading] = useState(false);
    const store = useForgottenPasswordStore();
    const dispatchPassword = useForgottenPasswordDispatch();
    const [isEmpty, setIsEmpty] = useState();
    const [userData, setUserData, errorMsg, setErrorMsg] = useFormInputs({
        code: "",
    });

    useEffect(() => {
        userData.code && userData.code.length === 4 && setIsEmpty(false);
    }, [userData.code]);

    const handleCodeInput = () => {
        (userData.code && userData.code.length === 4 && handleSubmit()) || setIsEmpty(true);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const res = await API.verifyResetPassword({
                username: store.username,
                phone: store.phone,
                code: userData.code,
            });

            dispatchPassword({
                type: "SET_HASH",
                value: { hash: res.data.hash },
            });

            onNext();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErrorMsg(requsetErrorMessage(error && error));
        }
    };

    return (
        <div className="Code-Input">
            <ReactCodeInput
                type="number"
                fields={4}
                autoComplete="false"
                onChange={(e) => setUserData({ code: e })}
            />
            {isEmpty && <span className="phoneInputError-2">Must be 4 characters!</span>}
            <p className="react-code-input">{errorMsg && <ErrorMsg message={errorMsg} />}</p>

            <Button
                label="Continue"
                color="orange"
                onClick={handleCodeInput}
                loading={loading}
                disabled={loading}
                style={{ marginTop: "10px" }}
            />
        </div>
    );
}
