import React, { createRef } from "react";
import ProfileInfo from "./../ProfileInfo";
import { Link, useLocation } from "react-router-dom";
import ProfileIcon from "./../../assets/images/icons/profile.svg";
import { useUserStore } from "../../contexts/userContext";
import useProfileMenu from "../../customHooks/useDisplayableItem";
import paths from "../../constants/paths";

export default function ProfileMenu({ onLogOut, onClickEvent }) {
    const { user } = useUserStore();
    const location = useLocation();
    const menuRef = createRef();
    const [showMenu, setShowMenu] = useProfileMenu(false, menuRef, []);

    return (
        <div ref={menuRef} className="Profile-Menu">
            <div
                className={`nav-link hide-mob ${
                    location.pathname === paths.PROFILE ? "selected" : ""
                }`}
                onClick={() => setShowMenu(true)}
            >
                <div className="icon-wrapper orange">
                    <img src={ProfileIcon} alt="scan icon" />
                </div>
            </div>
            <Link
                className={`nav-link hide ${location.pathname === paths.PROFILE ? "selected" : ""}`}
                to={paths.PROFILE}
                onClick={onClickEvent}
            >
                <div className="icon-wrapper orange">
                    <img src={ProfileIcon} alt="post icon" />
                </div>
                <span>Profile</span>
            </Link>
            <div className={`Profile-Menu-Dropdown ${showMenu ? "show" : ""}`}>
                <div className="Profile-Menu-Dropdown-Item profile">
                    <ProfileInfo
                        avatarSrc={user.avatar ? user.avatar : ""}
                        name={user.name ? user.name : "Service Name"}
                        username={user.username ? user.username : "username"}
                    />
                </div>
                <div className="Profile-Menu-Dropdown-Item">
                    <Link
                        to={paths.PROFILE}
                        className={location.pathname === paths.PROFILE ? "selectedProfile" : ""}
                        onClick={() =>
                            showMenu && location.pathname !== paths.PROFILE && setShowMenu(false)
                        }
                    >
                        View Profile
                    </Link>
                </div>
                <div className="Profile-Menu-Dropdown-Item">
                    <Link
                        to={paths.SALES}
                        className={location.pathname === paths.SALES ? "selectedProfile" : ""}
                        onClick={() =>
                            showMenu && location.pathname !== paths.SALES && setShowMenu(false)
                        }
                    >
                        Sales
                    </Link>
                </div>
                <div className="Profile-Menu-Dropdown-Item">
                    <Link
                        to={paths.BANK_ACCOUNT}
                        className={
                            location.pathname === paths.BANK_ACCOUNT ? "selectedProfile" : ""
                        }
                        onClick={() =>
                            showMenu &&
                            location.pathname !== paths.BANK_ACCOUNT &&
                            setShowMenu(false)
                        }
                    >
                        Bank Account
                    </Link>
                </div>
                <div className="Profile-Menu-Dropdown-Item">
                    <span
                        className="asLink"
                        onClick={() => {
                            onLogOut();
                            setShowMenu(false);
                        }}
                    >
                        Log Out
                    </span>
                </div>
            </div>
        </div>
    );
}
