import { useState, useEffect } from "react";

const useScrolledToTop = () => {
    const [isScrolledToTop, setIsScrolledToTop] = useState(true);

    useEffect(() => {
        const scrollHandler = () => {
            if (window.pageYOffset >= 40) {
                setIsScrolledToTop(false);
            } else {
                setIsScrolledToTop(true);
            }
        };

        window.addEventListener("scroll", scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);
    });

    return isScrolledToTop;
};

export default useScrolledToTop;
