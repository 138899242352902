import createStore from "../store";
import { reducer } from "../reducers/newPostReducer";

const initalState = {
    newPost: {
        title: "",
        description: "",
        isimage: 1,
        mediaurl: "",
        tags: [],
        media_offset: { ratio: 0, y: 0 },
    },
    errorMsg: "",
    loading: false,
    tagged: [],
    croppedBlob: null,
};
const [NewPostProvider, useNewPostStore, useNewPostDispatch] = createStore(reducer, initalState);

export { NewPostProvider, useNewPostStore, useNewPostDispatch };
