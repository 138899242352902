import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import navigationConfig from "../../configs/navigation";
import { useLocation, useHistory } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import VoucherIcon from "./../../assets/images/icons/voucher.svg";
import PostIcon from "./../../assets/images/icons/post.svg";
import PostsIcon from "./../../assets/images/icons/posts.svg";
import ScanIcon from "./../../assets/images/icons/scan.svg";
import LoginIcon from "./../../assets/images/icons/login.svg";
import NewsIcon from "./../../assets/images/icons/news.svg";
import TermsIcon from "./../../assets/images/icons/terms.svg";
import LogOutIcon from "./../../assets/images/icons/logout_white.svg";
import WalletIcon from "./../../assets/images/icons/wallet.svg";
import CreditCardIcon from "./../../assets/images/icons/card.svg";
import API from "../../APIs/auth";
import useLoginCheck from "./../../customHooks/useLoginCheck";
import useScrolledToTop from "./../../customHooks/useScrolledToTop";
import paths from "../../constants/paths";
import { useUserDispatch } from "../../contexts/userContext";

const isIgonred = (pathname) => {
    return navigationConfig.ignoredOnPages.find((page) => pathname.includes(page));
};

export default function Navigation({ showMenu, onClickEvent }) {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useUserDispatch();
    const [hideNav, setHideNav] = useState(isIgonred(location.pathname));
    const userLoggedIn = useLoginCheck();
    const isScrolledToTop = useScrolledToTop();

    useEffect(() => {
        setHideNav(isIgonred(location.pathname));
    }, [location]);

    const handleLogout = async () => {
        try {
            await API.logout();
            onClickEvent();
            dispatch({
                type: "CREATE_USER",
                value: {
                    id: "",
                    username: "",
                    name: "",
                    avatar: "",
                },
            });
            history.push(paths.HOME);
        } catch (error) {
            console.log("error -> ", error);
        }
    };

    return hideNav ? null : (
        <div className={`Nav ${isScrolledToTop ? "" : "green-style"}`}>
            <div className={`Nav-Inner ${showMenu ? "show" : ""}`}>
                <div className="Nav-Inner-Menu">
                    <div className="Nav-Inner-Menu-Main">
                        <Link
                            className={`nav-link ${
                                location.pathname === paths.HOME ? "selected" : ""
                            }`}
                            to={paths.HOME}
                            onClick={onClickEvent}
                        >
                            <div className="icon-wrapper green hide">
                                <img src={NewsIcon} alt="news icon" />
                            </div>
                            <span>News</span>
                        </Link>
                        <Link
                            className={`nav-link ${
                                location.pathname === paths.TERMS ? "selected" : ""
                            }`}
                            to={paths.TERMS}
                            onClick={onClickEvent}
                        >
                            <div className="icon-wrapper green hide">
                                <img src={TermsIcon} alt="terms icon" />
                            </div>
                            <span>Terms</span>
                        </Link>
                    </div>
                    {userLoggedIn ? (
                        <div className="Nav-Inner-Menu-User">
                            <Link
                                className={`nav-link ${
                                    location.pathname === paths.VOUCHERS ? "selected" : ""
                                }`}
                                to={paths.VOUCHERS}
                                onClick={onClickEvent}
                            >
                                <div className="icon-wrapper green hide">
                                    <img src={VoucherIcon} alt="voucher icon" />
                                </div>
                                <span>Vouchers</span>
                            </Link>
                            <Link
                                className={`nav-link ${
                                    location.pathname === paths.POSTS ? "selected" : ""
                                }`}
                                to={paths.POSTS}
                                onClick={onClickEvent}
                            >
                                <div className="icon-wrapper green hide">
                                    <img src={PostsIcon} alt="posts icon" />
                                </div>
                                <span>Posts</span>
                            </Link>
                        </div>
                    ) : null}
                </div>
                <div className="Nav-Inner-Right">
                    {userLoggedIn ? (
                        <div className="Nav-Inner-Right-LoggedIn">
                            <Link
                                className={`nav-link hide ${
                                    location.pathname === paths.SALES ? "selected" : ""
                                }`}
                                to={paths.SALES}
                                onClick={onClickEvent}
                            >
                                <div className="icon-wrapper green hide">
                                    <img src={WalletIcon} alt="wallet icon" />
                                </div>
                                <span>Sales</span>
                            </Link>
                            <Link
                                className={`nav-link hide ${
                                    location.pathname === paths.BANK_ACCOUNT ? "selected" : ""
                                }`}
                                to={paths.BANK_ACCOUNT}
                                onClick={onClickEvent}
                            >
                                <div className="icon-wrapper green hide">
                                    <img src={CreditCardIcon} alt="wallet icon" />
                                </div>
                                <span>Bank Account</span>
                            </Link>
                            <Link
                                className={`nav-link ${
                                    location.pathname === paths.NEW_VOUCHER ? "selected" : ""
                                }`}
                                to={paths.NEW_VOUCHER}
                                title="New Voucher"
                                onClick={onClickEvent}
                            >
                                <div className="icon-wrapper orange">
                                    <img src={VoucherIcon} alt="voucher icon" />
                                </div>
                                <span className="hide">New Voucher</span>
                            </Link>
                            <Link
                                className={`nav-link ${
                                    location.pathname === paths.NEW_POST ? "selected" : ""
                                }`}
                                to={paths.NEW_POST}
                                title="New Post"
                                onClick={onClickEvent}
                            >
                                <div className="icon-wrapper orange">
                                    <img src={PostIcon} alt="post icon" />
                                </div>
                                <span className="hide">New Post</span>
                            </Link>
                            <Link
                                className={`nav-link ${
                                    location.pathname === paths.SCAN ? "selected" : ""
                                }`}
                                to={paths.SCAN}
                                title="Scan Voucher"
                                onClick={onClickEvent}
                            >
                                <div className="icon-wrapper orange">
                                    <img src={ScanIcon} alt="scan icon" />
                                </div>
                                <span className="hide">Scan</span>
                            </Link>
                            <ProfileMenu onLogOut={handleLogout} onClickEvent={onClickEvent} />
                            <div className="nav-link hide" onClick={handleLogout}>
                                <div className="icon-wrapper orange">
                                    <img src={LogOutIcon} alt="logout icon" />
                                </div>
                                <span className="hide">Log Out</span>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="nav-link"
                            onClick={() => {
                                history.push(paths.LOGIN);
                                onClickEvent();
                            }}
                        >
                            <div className="icon-wrapper orange" title="Login">
                                <img className="small" src={LoginIcon} alt="login icon" />
                            </div>
                            <span className="hide">Login</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
