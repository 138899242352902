import React, { createRef, useEffect, useState } from "react";
import Page from "./../../components/Page";
import Paper from "./../../components/Paper";
import PostIcon from "./../../assets/images/icons/post.svg";
import API from "../../APIs/newPost";
import { useHistory } from "react-router";
import ImageCropper from "../../components/ImageCropper";
import requsetErrorMessage from "./../../helpers/requsetErrorMessage";
import {
    NewPostProvider,
    useNewPostStore,
    useNewPostDispatch,
} from "./../../contexts/newPostContext";
import { PostFormProvider } from "./../../contexts/postFormContext";
import Form from "./Form";
import axios from "axios";
import paths from "../../constants/paths";

const NewPost = () => {
    const fileImageRef = createRef();
    const history = useHistory();
    const { newPost, loading, croppedBlob } = useNewPostStore();
    const dispatch = useNewPostDispatch();
    const [mediaError, setMediaError] = useState(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const newPostHandler = async () => {
        try {
            const signatureAjax = await fetch(`/webapi/uploadsignature?timestamp=${Date.now()}`, {
                method: "POST",
            });
            const { api_key, signature, timestamp, upload_url } = await signatureAjax.json();

            const reader = new FileReader();
            reader.onloadend = async function () {
                const base64data = reader.result;

                const formData = new FormData();
                formData.append("file", base64data);
                formData.append("api_key", api_key);
                formData.append("timestamp", timestamp);
                formData.append("signature", signature);
                const uploadAjax = await fetch(upload_url, { method: "POST", body: formData });
                const { secure_url } = await uploadAjax.json();

                try {
                    const postParams = { ...newPost, ...{ mediaurl: secure_url } };
                    await API.createPost(postParams, {
                        cancelToken: source.token,
                    });
                    history.push(paths.POSTS);
                } catch (error) {
                    dispatch({
                        type: "ERROR",
                        errorMsg: requsetErrorMessage(error && error),
                    });
                }
            };
            reader.readAsDataURL(croppedBlob);
        } catch (error) {
            dispatch({
                type: "ERROR",
                errorMsg: "Please upload an image for your post",
            });
        }
    };

    useEffect(() => {
        return () => {
            source.cancel();
        };
    });

    return (
        <Page>
            <Paper title="Create New Post" icon={PostIcon} iconBackground="orange">
                <div className="New-Post">
                    <div className="New-Post-Col">
                        <ImageCropper
                            isDisabled={loading}
                            ref={fileImageRef}
                            blobCropped={(blob, videoData) => {
                                !videoData &&
                                    dispatch({
                                        type: "SET_IMAGE",
                                        value: blob,
                                    });
                                videoData &&
                                    dispatch({
                                        type: "SET_VIDEO",
                                        value: blob,
                                        videoData: videoData,
                                    });
                            }}
                            mediaError={mediaError}
                            isVideoAllowed={true}
                        />
                    </div>
                    <Form
                        handleNewPost={newPostHandler}
                        mediaRequierd={() => setMediaError(true)}
                    />
                </div>
            </Paper>
        </Page>
    );
};

export default function NewPostWithProvider() {
    return (
        <NewPostProvider>
            <PostFormProvider>
                <NewPost />
            </PostFormProvider>
        </NewPostProvider>
    );
}
