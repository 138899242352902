import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import StarIcon from "./../../assets/images/icons/star.svg";

export default function Paper({
    icon = "",
    title = "",
    children,
    iconBackground = "",
    rating = null,
    onRatingClick,
}) {
    return (
        <ScrollAnimation animateIn="fadeIn" animateOnce>
            <div className="Paper">
                <div className="Paper-Header">
                    <div
                        className={[
                            "icon-wrapper",
                            iconBackground === "orange" ? "orange" : "",
                            iconBackground === "green" ? "green" : "",
                        ].join(" ")}
                    >
                        {icon && <img src={icon} alt="header icon" />}
                    </div>
                    <p className="title">{title}</p>
                    {rating !== null && (
                        <div className="icon-wrapper rating" onClick={onRatingClick}>
                            <span>{rating === 0 ? "0.0" : rating}</span>
                            <img src={StarIcon} alt="star icon" />
                        </div>
                    )}
                </div>
                {children}
            </div>
        </ScrollAnimation>
    );
}
