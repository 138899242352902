import api from "./APIlibrary";

const API = {
    getBankAccountDetails(params) {
        return api.get("payoutdetails", params);
    },
    updateBankAccountDetails(params) {
        return api.put("payoutdetails", params);
    },
};

export default API;
