import React, { useState, useEffect } from "react";
import Button from "./../Button";
import Input from "./../Input";
import BackIcon from "./../../assets/images/icons/arrow_left.svg";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import ErrorMsg from "../ErrorMsg";
import requsetErrorMessage from "./../../helpers/requsetErrorMessage";
import useFormInputs from "./../../customHooks/useFormInputs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { countries } from "../../constants/countries";
import API from "../../APIs/auth";
import { useForgottenPasswordDispatch } from "../../contexts/passwordContext";

export default function UserPhoneNumber({ onNext }) {
    const dispatchPassword = useForgottenPasswordDispatch();
    const history = useHistory();
    const [userData, setUserData, errorMsg, setErrorMsg] = useFormInputs({
        username: "",
        phone: "",
    });
    const [isEmpty, setIsEmpty] = useState(false);

    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        criteriaMode: "all",
    });

    const handleNext = async () => {
        setErrorMsg("");
        setLoading(true);
        try {
            await API.resetPassword({ username: userData.username, phone: userData.phone });
            dispatchPassword({
                type: "SET_USER",
                value: { username: userData.username, phone: userData.phone },
            });
            onNext();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErrorMsg(requsetErrorMessage(error && error));
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                handleSubmit(handleNext);
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    });

    const handlePhoneInput = () => {
        if (userData.phone !== "" && userData.phone.length > 5) {
            setIsEmpty(false);
            handleNext();
        } else if (!isEmpty) {
            setIsEmpty(true);
        }
    };
    return (
        <form onSubmit={handleSubmit(handlePhoneInput)} style={{ width: "100%" }}>
            <Input
                placeholder="Username"
                name="username"
                onChange={setUserData}
                errors={errors}
                ref={register({ required: "Required" })}
                theme="light"
                noLabel
            />
            <PhoneInput
                country={"bh"}
                onlyCountries={countries}
                value={userData.phone}
                onChange={(phone) => setUserData({ ...userData, phone: `+${phone}` })}
                inputProps={{
                    name: "phone",
                    required: true,
                    style: { width: "100%", height: "40px" },
                }}
            />
            {errorMsg && <ErrorMsg message={errorMsg} />}
            {isEmpty && <span className="phoneInputError">Required</span>}

            <Button
                label="Continue"
                color="orange"
                type="submit"
                loading={loading}
                disabled={loading}
                style={{ marginTop: "30px" }}
            />
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                }}
            >
                <span style={{ cursor: "pointer" }} onClick={() => history.goBack()} title="Back">
                    <img src={BackIcon} alt="arrow icon" style={{ width: "20px" }} />
                </span>
            </div>
        </form>
    );
}
