import React, { useEffect, useState, createRef } from "react";
import Page from "./../../components/Page";
import Paper from "./../../components/Paper";
import WalletIcon from "./../../assets/images/icons/wallet.svg";
import API from "../../APIs/sales";
import { formatDate } from "./../../helpers/formatDate";
import PriceBox from "./PriceBox";
import VoucherList from "./VoucherList";
import SideDrawer from "./../../components/SideDrawer";
import VoucherView from "./../../components/VoucherView";
import useDrawer from "./../../customHooks/useDisplayableItem";
import voucerAPI from "../../APIs/vouchers";

export default function Sales() {
    const [currentCycle, setCurrentCycle] = useState({});
    const [selected, setSelected] = useState("delivered");
    const drawerRef = createRef();
    const [showDrawer, setShowDrawer] = useDrawer(false, drawerRef, ["voucher"]);
    const [voucherForView, setVoucherForView] = useState({});

    useEffect(() => {
        const getCurrentCycle = async () => {
            try {
                const res = await API.getCurrentCycle();
                setCurrentCycle(res.data);
            } catch (error) {
                console.log("error -> ", error);
            }
        };
        getCurrentCycle();
    }, []);

    const voucherSelectedHanlder = async (id) => {
        try {
            const res = await voucerAPI.getVoucher(id);
            setVoucherForView(res.data);
            setShowDrawer(true);
        } catch (error) {
            console.log("error -> ", error);
        }
    };

    return (
        <Page>
            <Paper title="Sales" icon={WalletIcon} iconBackground="green">
                <div className="Sales">
                    <h2 className="title">
                        {currentCycle &&
                            currentCycle.cycle_num &&
                            "Payment Cycle : " + currentCycle.cycle_num}
                    </h2>
                    <p className="subtitle">
                        {currentCycle &&
                            currentCycle.dateFrom &&
                            currentCycle.dateTo &&
                            formatDate(new Date(currentCycle.dateFrom)) +
                                " - " +
                                formatDate(new Date(currentCycle.dateTo))}
                    </p>
                    <div className="Sales-Boxes">
                        <PriceBox
                            boxName="Available Balance"
                            price={
                                currentCycle &&
                                currentCycle.balance &&
                                currentCycle.balance.value.toFixed(3)
                            }
                            details={
                                currentCycle &&
                                currentCycle.balance &&
                                currentCycle.balance.payoutDate &&
                                "Next pay-out on " +
                                    formatDate(new Date(currentCycle.balance.payoutDate))
                            }
                        />
                        <PriceBox
                            boxName="Delivered Vouchers"
                            price={
                                currentCycle &&
                                currentCycle.delivered &&
                                currentCycle.delivered.toFixed(3)
                            }
                            details="Vouchers that are used to date"
                        />
                        <PriceBox
                            boxName="Pending Vouchers"
                            price={
                                currentCycle &&
                                currentCycle.pending &&
                                currentCycle.pending.toFixed(3)
                            }
                            details="Vouchers that are not used to date"
                        />
                    </div>
                    <div className="Sales-Tabs">
                        <button
                            disabled={selected === "delivered"}
                            onClick={() => setSelected("delivered")}
                        >
                            Delivered
                        </button>
                        <button
                            disabled={selected === "pending"}
                            onClick={() => setSelected("pending")}
                        >
                            Pending
                        </button>
                    </div>
                    <VoucherList
                        selected={selected}
                        onVoucherSelected={(id) => {
                            voucherSelectedHanlder(id);
                        }}
                    />
                </div>
            </Paper>
            <SideDrawer
                ref={drawerRef}
                visible={showDrawer}
                hideDrawer={() => setShowDrawer(false)}
            >
                <VoucherView voucher={voucherForView} isCancelAvailable={false} />
            </SideDrawer>
        </Page>
    );
}
