import React, { createRef, useRef, useEffect, useState } from "react";
import Input from "./../../components/Input";
import Button from "./../../components/Button";
import AddIcon from "./../../assets/images/icons/add.svg";
import Tagged from "./Tagged";
import SideDrawer from "./../../components/SideDrawer";
import TagFollowers from "./TagFollowers";
import ErrorMsg from "./../../components/ErrorMsg";
import { useForm } from "react-hook-form";
import useDrawer from "../../customHooks/useDisplayableItem";
import { usePostFormDispatch, usePostFormStore } from "./../../contexts/postFormContext";
import { useNewPostStore, useNewPostDispatch } from "./../../contexts/newPostContext";

export default function Form({ handleNewPost, mediaRequierd }) {
    const descriptionRef = createRef();
    const drawerRef = useRef();
    const { formData } = usePostFormStore();
    const { errorMsg, loading, croppedBlob } = useNewPostStore();
    const dispatch = usePostFormDispatch();
    const dispatchNewPost = useNewPostDispatch();
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const [isFormReady, setIsFomrReady] = useState(false);
    const [showDrawer, setShowDrawer] = useDrawer(false, drawerRef, ["tag-btn"]);

    const onSubmit = async () => {
        setIsFomrReady(false);
        dispatchNewPost({
            type: "CREATE_NEW_POST",
            formData: { ...formData },
            loading: true,
        });
        await setIsFomrReady(true);
    };

    useEffect(() => {
        if (isFormReady) {
            handleNewPost();
        }
    }, [isFormReady, handleNewPost]);

    useEffect(() => {
        if (errors.media) {
            mediaRequierd();
        }
    }, [errors.media, mediaRequierd]);

    return (
        <div className="New-Post-Col">
            <form onSubmit={handleSubmit(onSubmit)}>
                {!croppedBlob && (
                    <input
                        className="hidden-input"
                        name="media"
                        type="text"
                        ref={register({
                            required: true,
                        })}
                    />
                )}
                <Input
                    label="Title"
                    name="title"
                    onChange={(e) =>
                        dispatch({
                            type: "CHANGE_VALUE",
                            data: e.target.value,
                            field: "title",
                        })
                    }
                    maxLenght="20"
                    errors={errors}
                    ref={register({
                        required: "Required",
                        minLength: {
                            value: 3,
                            message: "Title must be from 3 to 20 characters",
                        },
                    })}
                    disabled={loading}
                />
                <Input
                    label="Description"
                    isTextarea
                    maxLenght="120"
                    ref={descriptionRef}
                    hasCounter
                    name="description"
                    value={formData.description}
                    onChange={(e) =>
                        dispatch({
                            type: "CHANGE_VALUE",
                            data: e.target.value,
                            field: "description",
                        })
                    }
                    disabled={loading}
                />
                <div className="tagged">
                    <p className="label">Tag People</p>
                    <div className="tags">
                        <button
                            type="button"
                            className="tag-btn"
                            onClick={() => setShowDrawer(true)}
                            disabled={showDrawer || loading}
                        >
                            <img src={AddIcon} alt="tag icon" />
                        </button>
                        <Tagged />
                    </div>
                </div>
                {errorMsg && <ErrorMsg message={errorMsg} color="orange" />}
                <Button
                    color="green"
                    label="Publish"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                />
            </form>
            <SideDrawer
                ref={drawerRef}
                visible={showDrawer}
                hideDrawer={() => setShowDrawer(false)}
            >
                {showDrawer && <TagFollowers />}
            </SideDrawer>
        </div>
    );
}
