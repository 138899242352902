import Page from "./../components/Page";
import Title from "./../components/Title";
import Paper from "./../components/Paper";
import TermsIcon from "./../assets/images/icons/terms.svg";

const Terms = () => {
    return (
        <Page>
            <Paper title="Terms" icon={TermsIcon} iconBackground="green">
                <div className="terms">
                    <Title text="TERMS OF USE FOR THE ABALDA PROXFIT SERVICE" />

                    <p className="terms_text">
                        These Terms of Use (“terms of use”) apply to the Proxfit service (the
                        “Service) as may from time to time be made available to you ("you" or
                        "User") by ABALDA MARKETING WLL. (“ABALDA” and “we” or “us” or “our). PLEASE
                        READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION
                        REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS
                        LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW
                        DISPUTES WILL BE RESOLVED.
                    </p>

                    <h3 className="terms_heading">1. Acceptance of Terms</h3>
                    <p className="terms_text">
                        By installing/subscribing/signing-up into Proxfit website/mobile application
                        (the “Proxfit App”) you signify that you have read, understand and agree to
                        be bound by the terms of use in effect at the time of using the Service.
                    </p>
                    <p className="terms_text">
                        If User does not agree with these Terms and Conditions, User may not use the
                        Services.
                    </p>
                    <p className="terms_text">User further agrees and acknowledges that:</p>
                    <p className="terms_text indent_paragraph">
                        A. These terms of use incorporate by reference other terms and conditions
                        applicable to use of the Services, including, but not limited to, the
                        Privacy Policy and Disclosure. By using the Services, User agrees to comply
                        with all of the terms and conditions hereof.
                    </p>
                    <p className="terms_text indent_paragraph">
                        B. The right to use the Service is personal to User and is not transferable
                        to any other person or entity. User is responsible for all use of User's
                        account created within Proxfit App under any User name or password (the
                        “User's Account”) and for ensuring that all use of User's Account complies
                        fully with the provisions of these terms of use. User shall be responsible
                        for protecting the confidentiality of User's password(s), if any.{" "}
                    </p>
                    <p className="terms_text indent_paragraph">
                        C. ABALDA shall have the right at any time to change or discontinue any
                        aspect or feature of the Services, including, but not limited to, content,
                        hours of availability, methods and tools needed for access or use.
                    </p>

                    <h3 className="terms_heading">2. Changed Terms and Conditions</h3>
                    <p className="terms_text">
                        These terms of use are subject to change without prior written notice at any
                        time, in our sole discretion, and such changes shall apply after such
                        changes are posted to Proxfit App. Therefore, you should review these terms
                        of use prior to using the Service so you will understand the terms
                        applicable to the Service. The most current version of the terms of use can
                        be reviewed during signup and/or from the legal section on proxfit.com.
                    </p>

                    <h3 className="terms_heading">3. Service</h3>
                    <p className="terms_text">
                        The Service is a dual tier multi platform mobile application that allows the
                        Users (i.e. purchasers and service providers) to interact through a
                        commercial social network platform focused around lifestyle and fitness. The
                        main features and functionalities revolve around the ability of Users to
                        share experiences through media uploads and sharing alongside providing a
                        marketplace to create, publish, offer, grant, sell and buy vouchers relating
                        to fitness services and products such as gym memberships, personal training,
                        therapy and dietary supplements.
                    </p>
                    <p className="terms_text">
                        We reserve the right to amend or modify the Service, extend or reduce
                        functionality or availability of the Service or make any changes we deem
                        necessary or appropriate or to terminate the Service, at any time and
                        without notice to you.
                    </p>

                    <h3 className="terms_heading">4. Fees and Payment</h3>
                    <p className="terms_text">
                        We use a secure third-party payment processor, currently Benefit Bahrain
                        (BENEFIT) for debit cards payments and Credimax Bahrain (CREDIMAX) for
                        credit cards payments. By submitting an order through us, you authorize our
                        designated payment processor BENEFIT or CREDIMAX to charge the account you
                        specify for the purchase amount. We hereby acknowledge and confirm that we
                        do not store your credit card information, but we collect Users locations
                        and data for security purposes, market research and services development.
                    </p>
                    <p className="terms_text">
                        Upon purchasing a voucher from a service provider, the purchaser will be
                        directed to complete the transactions using either BENEFIT or CREDIMAX
                        payment processing gateway, or the payment options of the service provider,
                        as the case maybe. Payments can be made by Visa, MasterCard or any other
                        credit cards accepted by BENEFIT or CREDIMAX and ABALDA. The purchaser shall
                        be responsible for any applicable sales or use tax, duties, or other
                        governmental taxes or fees payable in connection with the purchase. If the
                        purchaser does not pay such sales or other tax or fee on a transaction,
                        he/she will be responsible for such taxes or fees in the event that they are
                        later determined to be payable on such sale. For any issues with service
                        providers the purchaser will need to contact us on billing@abalda.com.
                    </p>
                    <p className="terms_text">
                        Once a voucher is purchased, ABALDA will reserve its fees and will pay the
                        net amount to the service provider after the Purchaser scan the voucher and
                        receive the service. Service providers will be paid on a fortnightly basis.
                        All vouchers scanned will be paid during each payment cycle. Service
                        providers will have to specify and validate their payment options in order
                        to receive the due payments at each payment cycle. There will be no refund
                        option once the voucher is bought; however, end user will have the option of
                        gifting the purchased voucher to another user subject to the terms and
                        conditions of ABALDA and the service provider. The proceeds of the vouchers
                        bought but not consumed will be withheld by ABALDA until they scanned.
                    </p>
                    <p className="terms_text">
                        ABALDA shall have the right to change its fees at any time upon its
                        discretion without providing notice to the Users.
                    </p>

                    <h3 className="terms_heading">5. Term and Termination</h3>
                    <p className="terms_text">a) We may terminate your use of the Service:</p>
                    <p className="terms_text indent_paragraph">
                        i) at any time without cause by providing you with an electronic
                        notification seven days before the actual suspension of the Service; or
                    </p>
                    <p className="terms_text indent_paragraph">
                        ii) at any time upon breach by you of these terms of use of the Service,
                        with or without notice and without refund.
                    </p>
                    <p className="terms_text">
                        b) You may terminate your use of the Service with or without cause at any
                        time.
                    </p>
                    <p className="terms_text">
                        The ownership of any voucher that was purchased but not used by the end user
                        shall be automatically transferred to ABALDA upon the termination of the
                        Service.{" "}
                    </p>
                    <p className="terms_text">
                        The service provide shall remain responsible to accept any voucher purchased
                        before terminating the Service but used afterward.
                    </p>
                    <p className="terms_text">
                        We will use reasonable efforts to remove all links to your websites within a
                        reasonable period from your termination of your service.
                    </p>

                    <h3 className="terms_heading">6. Your Content</h3>
                    <p className="terms_text">
                        You are solely responsible for the information and content (including
                        without limitation text, photos, video) that you upload, publish or display
                        (hereinafter, "post") on or through the Service or Proxfit App,
                        (collectively the "User Content") and you grant us a non-exclusive license
                        to display the same. You understand and agree that we may, but are not
                        obligated to, review Proxfit App and may delete or remove (without notice)
                        or User Content in its sole discretion, and which may be exercised
                        arbitrarily, including User Content that in our sole judgment violates this
                        Agreement, or which might be misleading, offensive, illegal, or that might
                        violate the rights, harm, or threaten the safety of users or others. We will
                        do regular backups of User Content however this service is not guaranteed
                        (See Disclaimer of Warranties). For this reason, you are solely responsible
                        at your sole cost and expense for creating backup copies and replacing any
                        User Content you post or store on Proxfit App or provide to us.
                    </p>
                    <p className="terms_text">
                        You are responsible for ensuring that any material you provide to or through
                        the Service or post, including but not limited to text, photographs, video,
                        and audio, does not violate the copyright, trademark, trade secret, or any
                        other personal, moral, or proprietary rights of any third party or is posted
                        with the permission of the owner(s) of such rights and you agree to
                        indemnify and defend us from third party claims as provided for in these
                        terms of use.
                    </p>
                    <p className="terms_text">You agree that:</p>
                    <p className="terms_text">
                        a) you may not post any product that is not belong to you or your service;
                    </p>
                    <p className="terms_text">
                        b) you may not post any material that contains or links to any viruses,
                        worms, Trojan horses, corrupted data or other harmful, disruptive or
                        destructive files or programs;
                    </p>
                    <p className="terms_text">
                        c) you may not post any content that is infringing, obscene, offensive,
                        harassing, hateful, inflammatory, abusive, defamatory, harmful, fraudulent,
                        or invasive or privacy or publicity;
                    </p>
                    <p className="terms_text">
                        d) you may not promote, encourage or solicit illegal activity of any kind;
                    </p>
                    <p className="terms_text">
                        e) you will provide accurate, current and complete information about you as
                        may be prompted by any registration forms on Proxfit App;
                    </p>
                    <p className="terms_text">
                        f) ou will maintain the security of your password and identification;
                    </p>
                    <p className="terms_text">
                        g) you will maintain and promptly update the registration data, and any
                        other information you provide to us, to keep it accurate, current and
                        complete; and
                    </p>
                    <p className="terms_text">
                        h) you will be fully responsible for all use of your account and for any
                        actions that take place using your account.
                    </p>

                    <h3 className="terms_heading">7. Linking</h3>
                    <p className="terms_text">
                        We grant you the right to display the link to Proxfit App and proxfit.com at
                        your website(s) and the nonexclusive right to display publicly our trademark
                        “PROXFIT” in the form authorized by us. Any goodwill associated with our
                        trademarks automatically vest in us.
                    </p>
                    <p className="terms_text">
                        You grant us the right to display the link to your website at Proxfit App
                        and the nonexclusive right to display publicly your trademark in the form
                        presented by you to us. Any goodwill associated with your trademarks
                        automatically vest in you.
                    </p>

                    <h3 className="terms_heading">
                        8. Proprietary and Intellectual Property Rights to Content
                    </h3>
                    <p className="terms_text">
                        All content on Proxfit App or available through the Service, including
                        designs, articles, discussion forums, graphics, pictures, video,
                        information, applications, software, music, sound and other files, and their
                        selection and arrangement, name references or other material contained in
                        advertisements or search results, provided to or accessed by you via the
                        Service, and other materials related to the Proxfit App (the “Content”) are
                        our proprietary property with all rights reserved. Therefore, you are only
                        permitted to use this Content as expressly authorized by this Agreement or
                        by the relevant advertiser, as appropriate. No Content may be modified,
                        copied, distributed, framed, reproduced, republished, downloaded, scraped,
                        displayed, posted, transmitted, or sold in any form or by any means, in
                        whole or in part, without our prior written permission, except that the
                        foregoing does not apply to your User Content that you legally post on the
                        Proxfit App. You are granted a limited license to access and use the Proxfit
                        App and the Content for use by you or your customers provided that you do
                        not remove any of our identifying logos or trademarks. You may not create
                        derivative works of the Content without expressly being authorized to do so
                        in writing by us or the relevant party. You do not acquire ownership rights
                        to any content, document, or other materials viewed through Proxfit App.
                        Some of the content on the site is the copyrighted work of third parties.
                        Except for your User Content, you may not upload or republish Content on any
                        Internet, Intranet or Extranet site or incorporate the information in any
                        other database or compilation. Any use of Proxfit App or the Content other
                        than as specifically authorized herein, without our prior written permission
                        is strictly prohibited and will terminate the license granted herein. Such
                        unauthorized use may also violate applicable laws including copyright and
                        trademark laws and applicable communications regulations and statutes.
                        Unless explicitly stated herein, nothing in these terms of use shall be
                        construed as conferring any license to intellectual property rights, whether
                        by estoppels, implication or otherwise. This license is revocable at any
                        time without notice and with or without cause.
                    </p>

                    <h3 className="terms_heading">9. Our Trademarks</h3>
                    <p className="terms_text">
                        “PROXFIT” and certain product or feature names mentioned on Proxfit App are
                        our trademarks. Other product and company names mentioned on Proxfit App may
                        be trademarks and/or service marks of their respective owners.
                    </p>

                    <h3 className="terms_heading">10. Our Links</h3>
                    <p className="terms_text">
                        We and our users may provide links to other web sites or resources. You
                        acknowledge and agree that we are not responsible for the availability or
                        security of such external sites or resources, and we do not endorse and are
                        not responsible or liable for any content, advertising, products, or other
                        materials on or available from such sites or resources. You agree that we
                        shall not be responsible or liable, directly or indirectly, for any damage
                        or loss caused or alleged to be caused by or in connection with use of or
                        reliance on any such content, goods or services available on such external
                        sites or resources.
                    </p>

                    <h3 className="terms_heading">11. Disclaimer of Warranties</h3>
                    <p className="terms_text">
                        You expressly agree that use of the Service is at your sole risk. The
                        Service is provided on an “as is” and “as available” basis. We assume no
                        responsibility for the timeliness, deletion, mis delivery, or failure to
                        store any of your User Content. We do not guarantee the accuracy,
                        completeness, or usefulness of any information on the Service. We do not
                        warrant that use of the Service by you or other user will be uninterrupted,
                        always available, error-free or will meet your requirements, or that any
                        defects will be corrected.
                    </p>
                    <p className="terms_text">
                        We disclaim all warranties of any kind, express or implied, including
                        without limitation any warranty of merchantability, fitness for a particular
                        purpose, non-infringement or title. We make no warranty or representation
                        regarding the results that may be obtained from the use of the Service,
                        regarding the accuracy or reliability of any information obtained through
                        the Service, regarding any transactions entered into through the Service,
                        that defects will be corrected, or that the Service will meet any of your
                        requirements, be uninterrupted, timely, secure, or error free.
                    </p>
                    <p className="terms_text">
                        We make no warranty regarding any transactions entered into through the
                        Service. No advice or information, whether oral or written, obtained by you
                        from us or through the Service shall create any warranty not expressly made
                        herein.
                    </p>

                    <h3 className="terms_heading">12. Indemnification</h3>
                    <p className="terms_text">
                        YOU AGREE TO INDEMNIFY AND HOLD US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND
                        AFFILIATES, HARMLESS FROM ANY CLAIM OR DEMAND, INCLUDING REASONABLE
                        ATTORNEYS' FEES, MADE BY ANY THIRD PARTY DUE TO OR ARISING OUT OF YOUR USE
                        OF THE SERVICE, THE VIOLATION OF THIS AGREEMENT BY YOU, OR THE INFRINGEMENT
                        BY YOU, OR OTHER USER OF THE SERVICE USING YOUR COMPUTER, OF ANY
                        INTELLECTUAL PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY ARISING OUT OF
                        YOUR CONTENT, YOUR PROVISION OR FAILURE TO PROVIDE ANY PRODUCTS OR SERVICES
                        TO ANY PERSON INCLUDING WITHOUT LIMITATION CLAIMS BY THIRD PARTIES UNDER
                        WARRANTIES GIVEN BY YOU OR CLAIMS THAT THE PRODUCTS OR SERVICES PROVIDED BY
                        YOU ARE DEFECTIVE OR NOT SUITABLE.
                    </p>

                    <h3 className="terms_heading">13. Limitation on Liability</h3>
                    <p className="terms_text">
                        EXCEPT IN JURISDICTIONS WHERE SUCH PROVISIONS ARE RESTRICTED, IN NO EVENT
                        WILL WE OR OUR DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD
                        PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
                        PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM
                        YOUR PURCHASE OR USE OF THE SERVICE, EVEN IF WE ARE AWARE OR HAVE BEEN
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
                        CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER,
                        AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
                        THE AMOUNT PAID BY YOU TO US FOR THE SERVICE, BUT IN NO CASE WILL OUR
                        LIABILITY TO YOU EXCEED ONE HUNDRED (BHD100) BAHRANI DINAR.
                    </p>
                    <p className="terms_text">
                        CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
                        EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME
                        OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO
                        YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                    </p>

                    <h3 className="terms_heading">14. Laws</h3>
                    <p className="terms_text">
                        This agreement shall be interpreted, construed and governed by the laws of
                        the Kingdom of Bahrain, without reference to its laws relating to conflicts
                        of law.
                    </p>

                    <h3 className="terms_heading">15. Disputes and Arbitration</h3>
                    <p className="terms_text">
                        Before commencing any arbitration in the manner set out below, you and we
                        shall first attempt to resolve any dispute or differences between us by way
                        of good faith negotiation. The good faith negotiation shall commence by each
                        party communicating their position regarding the complaint, claim, dispute,
                        or controversy to the other party, and how the parties should resolve the
                        dispute. You and we shall then make good faith efforts to negotiate a
                        resolution of the claim, dispute, or controversy. Neither party shall
                        commence any arbitral proceedings unless and until the good faith
                        negotiation fails.
                    </p>
                    <p className="terms_text">
                        YOU AND WE AGREE THAT THE SOLE AND EXCLUSIVE FORUM AND REMEDY FOR ANY AND
                        ALL DISPUTES AND CLAIMS RELATING IN ANY WAY TO OR ARISING OUT OF THESE TERMS
                        OF USE OR THE SERVICE OR PROXFIT APP SHALL BE FINAL AND BINDING ARBITRATION,
                        except that, to the extent that either of us has in any manner infringed
                        upon or violated or threatened to infringe upon or violate the other party's
                        patent, copyright, trademark or trade secret rights, such other party may
                        seek injunctive or other appropriate relief.
                    </p>
                    <p className="terms_text">
                        Arbitration shall be final, binding and finally resolved, pursuant to the
                        arbitration rules of the GCC Commercial Arbitration Centre and the Bahraini
                        law. Judgment on upon the award rendered by the arbitrator(s) may be entered
                        into any court having jurisdiction thereof. The Arbitration Tribunal shall
                        consist of one (1) arbitrator. The place of the arbitration shall be in
                        Manama, Bahrain. The language of the arbitration shall be English. The
                        arbitration award shall be given in writing and shall be final, binding on
                        the parties, not subject to any appeal, and shall deal with the question of
                        costs of arbitration and all matters related thereto.
                    </p>
                    <p className="terms_text">
                        Judgment upon the award rendered may be entered into any court having
                        competent jurisdiction, or application may be made to such court for a
                        judicial recognition of the award or an order of enforcement thereof, as the
                        case may be.
                    </p>

                    <h3 className="terms_heading">16. Miscellaneous</h3>
                    <p className="terms_text">
                        Our failure to exercise or enforce any right or provision of this Agreement
                        shall not constitute a waiver of such right or provision unless expressly
                        acknowledged and agreed to by us in writing. You and we agree that any cause
                        of action arising out of or related to this Service must commence within
                        three (3) months after the cause of action arose; otherwise, such cause of
                        action is permanently barred. Some jurisdictions may prohibit the shortening
                        of the time period in which a cause of action must be brought. In all such
                        jurisdictions, the applicable time period shall be the minimum allowed by
                        law. The section titles in this Agreement are solely used for the
                        convenience of the parties and have no legal or contractual significance. If
                        any provision of this Agreement is held invalid, the remainder of this
                        Agreement shall continue in full force and effect. All notices to us shall
                        be made via email to contact@abalda.com. We may broadcast notices or
                        messages through the Service to inform you of changes to these terms of use,
                        the Service, or other matters of importance. We shall not be liable for any
                        delay or failure in delivery or performance of the Service caused by
                        circumstances beyond its reasonable control.
                    </p>

                    <h3 className="terms_heading">17. Entire Agreement</h3>
                    <p className="terms_text">
                        These terms of use, constitute the entire agreement between you and us
                        regarding your use of the Service, superseding any prior agreements between
                        you and us relating to the same. Our failure to exercise or enforce any
                        right or provision of these terms of use shall not constitute a waiver of
                        such right or provision in that or any other instance. If any provision of
                        these terms of use is held invalid, the remainder of these terms of use
                        shall continue in full force and effect. If any provision of these terms of
                        use shall be deemed unlawful, void or for any reason unenforceable, then
                        that provision shall be deemed severable from these terms of use and shall
                        not affect the validity and enforceability of any remaining provisions.
                    </p>

                    <Title text="PRIVACY POLICY" />

                    <p className="terms_text details_paragraph">
                        PLEASE READ THIS PRIVACY POLICY CAREFULLY.
                    </p>
                    <p className="terms_text">
                        This Privacy Policy is applicable to ABALDA MARKETING WLL at Bahrain.
                        (“ABALDA") and sets out our policy on the gathering and use of information
                        on Proxfit App (as defined in the Terms of Use). The ABALDA is committed to
                        providing safe Proxfit App for visitors and has implemented this Privacy
                        Policy to demonstrate ABALDA commitment to your privacy.{" "}
                    </p>
                    <p className="terms_text">
                        This Privacy Policy applies only to Proxfit App and not to mobile
                        applications and websites of other companies or organizations (Linked Sites)
                        to which Proxfit App may be linked. You must check on any Linked Sites for
                        the privacy policy that applies to that Linked Site. ABALDA does not
                        endorse, and is not responsible for, these Linked Sites.
                    </p>
                    <p className="terms_text">
                        When you install, subscribe or sign-up to access Proxfit App, you may be
                        asked to provide us with personal information when you visit certain
                        sections of Proxfit App. Your use of Proxfit App signifies your
                        acknowledgement and consent to our Privacy Policy. If you do not agree to
                        this Privacy Policy, please do not continue to use Proxfit App.
                    </p>

                    <h3 className="terms_heading">Collection of Personal Information</h3>
                    <p className="terms_text">
                        Personal Information is information about you that identifies you as an
                        individual, for example, your name, address, e-mail address, or telephone
                        number.
                    </p>
                    <p className="terms_text">
                        We collect information that you voluntarily provide to us through responses
                        to surveys, search functions, questionnaires, feedback, Tell Your Story
                        forms and the like. We may also ask you to provide additional information if
                        you want to obtain additional services, information, participate in a
                        contest or to resolve complaints or concerns.
                    </p>

                    <h3 className="terms_heading">
                        How Does ABALDA Use Your Personal Information?
                    </h3>
                    <p className="terms_text">
                        Before forwarding us any personal information, please be advised that any
                        information gathered may be used in the aggregate for research and
                        development relating to Proxfit App and/or for future mobile application
                        development. We may also use information gathered about you for the
                        following purposes: to monitor interest in our range of products and to
                        assist us to tailor the Content of Proxfit App to your needs by collecting
                        information about your preferences through tracking of patterns page views
                        on Proxfit App; to create a profile relating to you in order to show you the
                        content that might be of interest to you and to display the Content
                        according to your preferences; and, in circumstances where you have
                        indicated that you wish to receive additional information, to send you
                        information about us and promotional material about our products together
                        with details of any offers we may have available from time to time.{" "}
                    </p>

                    <h3 className="terms_heading">Promotional and Informational Offers</h3>
                    <p className="terms_text">
                        With your permission at the time of registration or submission, your
                        information may be used by us for marketing and promotional purposes. If you
                        object to such use for any reason, you may prevent that use, either by email
                        request or by modifying the registration information provided. ABALDA uses
                        reasonable efforts to maintain Users' information in a secure environment.
                        If you have submitted personal information and want to change it or opt-out,
                        please email us on contact@abalda.com.
                    </p>

                    <h3 className="terms_heading">Disclosure of Information</h3>
                    <p className="terms_text">
                        The ABALDA will not disclose your personal information that you provide on
                        Proxfit App except in accordance with the terms of Use, this Privacy Policy
                        and the “DISCLOSURE” annexed to it.{" "}
                    </p>

                    <h3 className="terms_heading">Cookies</h3>
                    <p className="terms_text">
                        ABALDA, in common with many mobile application and web site operators, may
                        use standard technology called "cookies" on its Proxfit Website. Cookies are
                        small data files that are downloaded onto your computer when you visit
                        Proxfit Website. You can disable cookies by turning them off in your
                        browser; however, some areas of Proxfit Website may not function properly if
                        you do so.
                    </p>

                    <Title text="DISCLOSURE" />

                    <h3 className="terms_heading">Disclosure of Personal Information</h3>
                    <p className="terms_text">
                        You hereby acknowledge and confirm that ABALDA may collect, use and disclose
                        or transfer your personal information as permitted by the applicable laws.
                    </p>
                    <p className="terms_text">
                        Without prejudicing the generality of the foregoing, you acknowledge and
                        confirm that ABALDA may disclose you personal information under the
                        following circumstances;
                    </p>
                    <p className="terms_text indent_paragraph">
                        - to Canadian and other governmental competent authorities as required, if
                        you are reporting an adverse event or side effect;
                    </p>
                    <p className="terms_text indent_paragraph">
                        - to our subsidiaries, affiliates, agents and outside service providers,
                        whom we require to assist us in carrying out our business and who have
                        agreed to protect the confidentiality of your personal information on terms
                        that are consistent with those set out in our Privacy Policy. Only personal
                        information that is required to provide the service in question will be
                        disclosed or transferred;
                    </p>
                    <p className="terms_text indent_paragraph">
                        - to a court, administrative tribunal, governmental authority or other body
                        authorized to compel the disclosure of your personal information, for the
                        purpose of complying with legal requirements such as a law, regulation,
                        search warrant, subpoena, or court or administrative order, or as otherwise
                        required or permitted by applicable law;
                    </p>
                    <p className="terms_text indent_paragraph">
                        - if the rights or property of ABALDA, its subsidiaries, affiliates, third
                        party service providers or the users of our website may be compromised;
                    </p>
                    <p className="terms_text indent_paragraph">
                        - if the personal safety of users of our website or other members of the
                        public may be compromised; and
                    </p>
                    <p className="terms_text indent_paragraph">
                        - in connection with a proposed or actual financing, securitization, sale,
                        merger, amalgamation, plan of arrangement, acquisition, conveyance,
                        assignment or other transfer or disposal of all or part of ABALDA or our
                        business or assets, for the purpose of evaluating and/or carrying out the
                        proposed transaction, and may use and disclose your personal information for
                        the same purposes for which the information was collected and on terms of
                        confidentiality that are consistent with those set out in our Privacy
                        Policy. Only personal information that is required for these purposes will
                        be disclosed or transferred.
                    </p>
                    <p className="terms_text">
                        Please note that as any of these disclosures may involve the storage or
                        processing of personal information outside of Bahrain and may therefore be
                        subject to different privacy laws than those applicable in Bahrain,
                        including laws that require the disclosure of personal information under
                        circumstances that are different than those that apply in Bahrain.
                    </p>

                    <h3 className="terms_heading">Protection of Information</h3>
                    <p className="terms_text">
                        We have put in place physical, electronic, and managerial procedures to
                        safeguard and help prevent unauthorized access, maintain data security, and
                        correctly use the information we collect online. ABALDA applies security
                        safeguards appropriate to the sensitivity of the information, such as
                        retaining information in secure facilities and making personal information
                        accessible only to authorized employees on a need-to-know basis.
                    </p>

                    <h3 className="terms_heading">Storage of Information</h3>
                    <p className="terms_text">
                        Personal information you share with us is stored on our database servers at
                        ABALDA data centers (in whatever country they may be located) or hosted by
                        third parties who have entered into agreements with us that require them to
                        observe our Privacy Policy.
                    </p>

                    <h3 className="terms_heading">
                        No Guarantee of Technical Security over the Internet
                    </h3>
                    <p className="terms_text">
                        Although we employ technical security measures to protect the
                        confidentiality of your personal information, no transmission over the
                        Internet can be guaranteed to be completely secured. Therefore, ABALDA, its
                        subsidiaries, affiliates, third party service providers and their respective
                        directors, officers, employees and agents do not represent, warrant or
                        guarantee that personal information will be protected against misuse, loss
                        or alterations and do not accept any liability for personal information
                        submitted to them nor for your or third parties’ use or misuse of personal
                        information. Further, it is your responsibility to safeguard the
                        confidentiality of your passwords that allow you access to secured areas of
                        Proxfit App.
                    </p>
                    <p className="terms_text">
                        BY PROVIDING US WITH YOUR PERSONAL INFORMATION, WE WILL ASSUME THAT YOU HAVE
                        CONSENTED TO OUR COLLECTION, USE AND DISCLOSURE OF THAT PERSONAL INFORMATION
                        FOR THE PURPOSE SPECIFIED, OR THE PURPOSE DESCRIBED, IN THIS DISCLOSURE, AT
                        THE TIME OF COLLECTION.
                    </p>

                    <h3 className="terms_heading">
                        ACCEPTANCE OF OUR PRIVACY POLICY AND DISCLOSURE:
                    </h3>
                    <p className="terms_text">
                        BY USING PROXFIT APP AND SUBMITTING YOUR INFORMATION, YOU SIGNIFY YOUR
                        ACCEPTANCE OF OUR PRIVACY POLICY AND DISCLOSURE; AND YOU ADHERE TO THE TERMS
                        OF USE POSTED ON PROXFIT APP.
                    </p>
                </div>
            </Paper>
        </Page>
    );
};

export default Terms;
