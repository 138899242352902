export const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

export const getCroppedImg = (imageUrl, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.onload = function () {
        ctx.drawImage(image, 0, 0);
    };
    image.src = imageUrl;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    canvas.id = "my-image";

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                if (blob === null) {
                    resolve(null);
                } else {
                    blob.name = fileName;
                    resolve(blob);
                }
            },
            "image/jpeg",
            1,
        );
    });
};

export const getVideoData = async (videoUrl, crop) => {
    const { ratio, originalWidth, originalHeight } = await getVideoRatio(videoUrl);
    const x = crop.x ? crop.x : 0;
    const y = crop.y ? crop.y : 0;

    return {
        videoData: { ratio: ratio, x: x, y: y },
        videoOriginalDimensions: { width: originalWidth, height: originalHeight },
    };
};
function getVideoRatio(url) {
    return new Promise(function (resolve) {
        // create the video element
        let video = document.createElement("video");

        // place a listener on it
        video.addEventListener(
            "loadedmetadata",
            function () {
                // retrieve dimensions
                let height = this.videoHeight;
                let width = this.videoWidth;
                // send back result
                resolve({ ratio: width / height, originalWidth: width, originalHeight: height });
            },
            false,
        );

        // start download meta-datas
        video.src = url;
    });
}
