import errorMessages from "../configs/errorcodes";

export const getErrorMessage = (error_name) => {
    if (errorMessages[error_name]) {
        return errorMessages[error_name];
    } else {
        return false;
    }
};

const requsetErrorMessage = (error, defaultMessage = "Ooops! Something went wrong.") => {
    let message = getErrorMessage(error);

    return message ? message : defaultMessage;
};

export default requsetErrorMessage;
