import { useState, useEffect } from "react";

const usePopup = (initial) => {
    const [popup, setPopup] = useState(initial);

    useEffect(() => {
        popup
            ? (document.querySelector("html").style.overflow = "hidden")
            : (document.querySelector("html").style.overflow = "auto");
    }, [popup]);

    return [popup, (value) => setPopup(value)];
};

export default usePopup;
