export const reducer = (state, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                username: action.value.username,
                phone: action.value.phone,
            };
        case "SET_HASH":
            return {
                ...state,
                hash: action.value.hash,
            };
        default:
            return state;
    }
};
