import createStore from "../store";
import { reducer } from "../reducers/postFromReducer";

const initalState = {
    formData: {
        title: "",
        description: "",
        tags: [],
    },
    tagged: [],
};
const [PostFormProvider, usePostFormStore, usePostFormDispatch] = createStore(reducer, initalState);

export { PostFormProvider, usePostFormStore, usePostFormDispatch };
