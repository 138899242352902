import React, { useState, useEffect } from "react";
import requsetErrorMessage from "../../helpers/requsetErrorMessage";
import ErrorIcon from "./../../assets/images/icons/scan_error_wrong.svg";
import Button from "./../../components/Button";

export default function ErrorScreen({ errorCode = "", onScanAgain }) {
    const [errorMsg, setErrorMsg] = useState("");
    useEffect(() => {
        setErrorMsg(requsetErrorMessage(errorCode && errorCode));
    }, [errorCode]);
    return (
        <div className="Scan-Error">
            <div className="Scan-Error-Icon">
                <img src={ErrorIcon} alt="scan error icon" />
            </div>
            <p>QR Code</p>
            <p className="error-msg">{errorMsg}</p>
            <Button color="green" label="Scan Again" onClick={() => onScanAgain()} />
        </div>
    );
}
