import React from "react";
import ImageIcon from "../../assets/images/icons/image.svg";
import ImageErrorIcon from "../../assets/images/icons/image-orange.svg";

export default function ImagePlaceholder({ fileInputRef, error = false }) {
    return (
        <div className="img-wrapper">
            <div
                className={`img-overlay ${error ? "with-error" : ""}`}
                onClick={() => fileInputRef.current.click()}
            >
                {!error ? (
                    <img src={ImageIcon} alt="img icon" />
                ) : (
                    <img src={ImageErrorIcon} alt="img error icon" />
                )}
                <span>Select Image</span>
            </div>
        </div>
    );
}
