import api from "./APIlibrary";

const API = {
    getCurrentCycle(params) {
        return api.get("payments", params);
    },
    getDeliveredCycles(params) {
        return api.get("payments/delivered", params);
    },
    getPendingCycles(params) {
        return api.get("payments/pending", params);
    },
};

export default API;
