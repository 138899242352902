import { forwardRef, useState, useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";

const Input = forwardRef(
    (
        {
            type = "text",
            name = "",
            placeholder = "",
            disabled = false,
            label = "",
            noLabel = false,
            isTextarea = false,
            noBorder = false,
            hasButton = false,
            buttonLabel = "",
            onClickEvent,
            errors = [],
            hasCounter = false,
            maxLenght = "",
            theme = "",
            className = "",
            ...restProps
        },
        ref,
    ) => {
        const [counter, setCounter] = useState(0);

        useEffect(() => {
            if (ref && ref.current) {
                setCounter(parseInt(maxLenght) - ref.current.value.length);
            }
        }, [ref, maxLenght]);
        return (
            <div className="Input">
                {!noLabel && (
                    <div className="label">
                        {label && <label>{label}</label>}
                        {hasCounter && <span className="counter">{counter}</span>}
                    </div>
                )}

                {isTextarea ? (
                    <textarea
                        ref={ref}
                        name={name}
                        maxLength={maxLenght}
                        className={[disabled ? "disabled" : "", noBorder ? "noBorder" : ""].join(
                            " ",
                        )}
                        placeholder={placeholder}
                        disabled={disabled}
                        {...restProps}
                    />
                ) : (
                    <input
                        ref={ref}
                        type={type}
                        name={name}
                        maxLength={maxLenght}
                        className={[
                            className,
                            disabled ? "disabled" : "",
                            noBorder ? "noBorder" : "",
                        ].join(" ")}
                        placeholder={placeholder}
                        disabled={disabled}
                        autoComplete="off"
                        {...restProps}
                    />
                )}
                <p className={["error-msg", theme === "light" ? "light" : ""].join(" ")}>
                    <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <span key={type}>{message}</span>
                            ))
                        }
                    />
                </p>
                {hasButton && (
                    <button className="Input-Button" onClick={onClickEvent}>
                        {buttonLabel}
                    </button>
                )}
            </div>
        );
    },
);

export default Input;
