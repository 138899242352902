import createStore from "../store";
import { reducer } from "../reducers/userReducer";

const localData = localStorage && localStorage.getItem("user");

const initialState = {
    user: localData ? JSON.parse(localData) : { id: "", username: "", name: "", avatar: "" },
};

const [UserProvider, useUserStore, useUserDispatch] = createStore(reducer, initialState);

export { UserProvider, useUserStore, useUserDispatch };
