import SuccessIcon from "./../../assets/images/icons/checked-green.svg";
import ProfileInfo from "./../../components/ProfileInfo";
import Button from "./../../components/Button";

export default function SuccessScreen({ voucherData = null, onScanAgain }) {
    return (
        <div className="Scan-Success">
            <div className="Scan-Success-Msg">
                <img src={SuccessIcon} alt="scan successfull icon" />
                <p>Voucher scanned successfully!</p>
            </div>
            {voucherData && voucherData.voucher && (
                <div className="Scan-Success-Voucher">
                    <div className="img-wrapper">
                        <img src={voucherData.voucher.image} alt="voucher" />
                    </div>
                    <p>{voucherData.voucher.title}</p>
                </div>
            )}
            {voucherData && voucherData.user && (
                <ProfileInfo
                    name={voucherData.user.name}
                    username={voucherData.user.username}
                    avatarSrc={voucherData.user.avatar_url}
                />
            )}
            <Button color="green" label="Back to Scanner" onClick={() => onScanAgain()} />
        </div>
    );
}
