import React, { useState, useEffect } from "react";
import Input from "./../../components/Input";
import ProfileInfo from "./../../components/ProfileInfo";
import Checkbox from "./../../components//Checkbox";
import WarningMsg from "./../../components/warningMsg";
import API from "../../APIs/newPost";
import { usePostFormStore, usePostFormDispatch } from "./../../contexts/postFormContext";
import NoResults from "./../../components/NoResults";

export default function TagFollowers() {
    const { tagged } = usePostFormStore();
    const dispatch = usePostFormDispatch();
    const [followers, setFollowers] = useState(null);
    const [search, setSearch] = useState("");
    const [warningMsg, setWarningMsg] = useState("");

    useEffect(() => {
        let queryObj = {
            params: {
                query: search,
            },
        };
        const getFollowers = async () => {
            try {
                const res = await API.getFollowers(search && queryObj);
                setFollowers(res.data);
            } catch (error) {
                console.log("error -> ", error);
            }
        };
        getFollowers();
    }, [search]);

    useEffect(() => {
        if (tagged.length >= 10) {
            setWarningMsg("You reached a maximum number of users");
        } else {
            setWarningMsg("");
        }
    }, [tagged]);

    const tagFollowerHandler = (follower, e) => {
        if (tagged.length >= 10 && e.target.checked) {
            return;
        }
        checkIfTagged(follower.id);

        let newTags = [...tagged];
        const isTagged = () => {
            return newTags.find((user) => user.id === follower.id);
        };
        if (!isTagged()) {
            newTags.push(follower);
            let tagsID = newTags.map((tag) => {
                return tag.id;
            });
            dispatch({
                type: "TAGGED",
                tagged: [...newTags],
                tagsID: [...tagsID],
            });
        } else {
            for (var i = 0; i < newTags.length; i++) {
                var user = newTags[i];
                if (user.id === follower.id) {
                    newTags.splice(i, 1);
                    let tagsID = newTags.map((tag) => {
                        return tag.id;
                    });
                    dispatch({
                        type: "TAGGED",
                        tagged: [...newTags],
                        tagsID: [...tagsID],
                    });
                }
            }
        }
    };

    const checkIfTagged = (id) => {
        let user = tagged.find((user) => user.id === id);
        return user ? true : false;
    };

    return (
        <div className="Tag-Followers">
            <Input
                placeholder="Search your followers..."
                onChange={(e) => setSearch(e.target.value)}
            />
            <WarningMsg text={warningMsg} />
            <div className="followers">
                {followers &&
                    followers.map((follower, i) => {
                        return (
                            <div id={follower.id} className="follower-row" key={i}>
                                <ProfileInfo
                                    name={follower.name}
                                    username={follower.username}
                                    avatarSrc={follower.avatar_url}
                                />
                                <Checkbox
                                    checked={checkIfTagged(follower.id)}
                                    onChange={(e) => tagFollowerHandler(follower, e)}
                                />
                            </div>
                        );
                    })}
            </div>
            {followers && followers.length === 0 ? (
                <NoResults message="No users you can tag..." />
            ) : null}
        </div>
    );
}
