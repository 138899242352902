import React, { useEffect, useState } from "react";
import Page from "./../components/Page";
import Paper from "./../components/Paper";
import CreditCardIcon from "./../assets/images/icons/card.svg";
import { useForm } from "react-hook-form";
import useFormInputs from "./../customHooks/useFormInputs";
import API from "../APIs/bankAccount";
import Input from "./../components/Input";
import Button from "./../components/Button";
import NotVerifiedIcon from "./../assets/images/icons/close_grey.svg";
import VerifiedIcon from "./../assets/images/icons/checked-green.svg";
import ErrorMsg from "../components/ErrorMsg";
import requsetErrorMessage from "./../helpers/requsetErrorMessage";

export default function BankAccountDetails() {
    const [bankDetails, setBankDetails, errorMsg, setErrorMsg] = useFormInputs({
        email: "",
        phone: "",
        address: "",
        bank_name: "",
        account_name: "",
        account_number: "",
        iban: "",
    });
    const [isVerified, setIsVerified] = useState("");
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });

    useEffect(() => {
        const getBankDetails = async () => {
            try {
                const res = await API.getBankAccountDetails();
                setBankDetails({
                    email: res.data.email ? res.data.email : "",
                    phone: res.data.phone ? res.data.phone : "",
                    address: res.data.address ? res.data.address : "",
                    bank_name: res.data.bank_name ? res.data.bank_name : "",
                    account_name: res.data.account_name ? res.data.account_name : "",
                    account_number: res.data.account_number ? res.data.account_number : "",
                    iban: res.data.iban ? res.data.iban : "",
                });
                setIsVerified(res.data.verified);
            } catch (error) {
                console.log("error -> ", error);
            }
        };
        getBankDetails();
    }, [setBankDetails]);

    const submitBankDetails = async () => {
        setLoading(true);
        try {
            await API.updateBankAccountDetails(bankDetails);
            setLoading(false);
            setIsVerified(0);
        } catch (error) {
            console.log("error -> ", error);
            setLoading(false);
            setErrorMsg(requsetErrorMessage(error && error));
        }
    };

    return (
        <Page>
            <Paper title="Bank Account Details" icon={CreditCardIcon} iconBackground="green">
                <div className="Bank-Account">
                    <div className="Bank-Account-Notes">
                        {(isVerified === null || isVerified === 0) && (
                            <p>
                                Please enter all necessary details and save them. We will call you
                                soon in order to verify your Bank Account details.
                            </p>
                        )}
                        {isVerified === 0 && (
                            <p>
                                <img src={NotVerifiedIcon} alt="not verified" />
                                Your bank account details are not verified yet.
                            </p>
                        )}
                        {isVerified === 1 && (
                            <>
                                <p className="verified">
                                    <img src={VerifiedIcon} alt="verified" />
                                    Bank Account Details are Verified
                                </p>
                                <p>
                                    If you want to change your Bank Account details please contact
                                    out support on <span>support@abalda.com</span>
                                </p>
                            </>
                        )}
                    </div>

                    <form onSubmit={handleSubmit(submitBankDetails)}>
                        <Input
                            disabled={isVerified !== null || loading}
                            label="Phone Number"
                            type="tel"
                            name="phone"
                            defaultValue={bankDetails.phone}
                            onChange={setBankDetails}
                            errors={errors}
                            maxLenght="16"
                            ref={register({
                                required: "Required",
                                pattern: {
                                    value: /^\+\d+$/,
                                    message: "Invalid phone number. Example: +1123456789",
                                },
                                minLength: {
                                    value: 8,
                                    message: "Phone number must be at least 8 characters",
                                },
                            })}
                        />
                        <Input
                            disabled={isVerified !== null || loading}
                            label="Email"
                            name="email"
                            defaultValue={bankDetails.email}
                            onChange={setBankDetails}
                            errors={errors}
                            maxLenght="256"
                            ref={register({
                                required: "Required",
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email format",
                                },
                                minLength: {
                                    value: 6,
                                    message: "Email must be at least 6 characters",
                                },
                            })}
                        />
                        <Input
                            disabled={isVerified !== null || loading}
                            label="Address"
                            name="address"
                            defaultValue={bankDetails.address}
                            onChange={setBankDetails}
                            errors={errors}
                            maxLenght="120"
                            ref={register({
                                required: "Required",
                                minLength: {
                                    value: 6,
                                    message: "Address must be from 6 to 120 characters",
                                },
                            })}
                        />
                        <Input
                            disabled={isVerified !== null || loading}
                            label="Bank Name"
                            name="bank_name"
                            defaultValue={bankDetails.bank_name}
                            onChange={setBankDetails}
                            errors={errors}
                            maxLenght="32"
                            ref={register({
                                required: "Required",
                                minLength: {
                                    value: 2,
                                    message: "Bank Name must be from 2 to 32 characters",
                                },
                            })}
                        />
                        <Input
                            disabled={isVerified !== null || loading}
                            label="Bank Account Name"
                            name="account_name"
                            defaultValue={bankDetails.account_name}
                            onChange={setBankDetails}
                            errors={errors}
                            maxLenght="32"
                            ref={register({
                                required: "Required",
                                minLength: {
                                    value: 2,
                                    message: "Bank Account Name must be from 2 to 32 characters",
                                },
                            })}
                        />
                        <Input
                            disabled={isVerified !== null || loading}
                            label="IBAN"
                            name="iban"
                            defaultValue={bankDetails.iban}
                            onChange={setBankDetails}
                            errors={errors}
                            maxLenght="34"
                            ref={register({
                                required: "Required",
                                minLength: {
                                    value: 15,
                                    message: "IBAN must be from 15 to 34 characters",
                                },
                            })}
                        />
                        <Input
                            disabled={isVerified !== null || loading}
                            label="Bank Account Number"
                            name="account_number"
                            defaultValue={bankDetails.account_number}
                            onChange={setBankDetails}
                            errors={errors}
                            maxLenght="17"
                            ref={register({
                                required: "Required",
                                minLength: {
                                    value: 4,
                                    message: "Bank Account Number must be from 4 to 17 characters",
                                },
                            })}
                        />
                        {errorMsg && <ErrorMsg message={errorMsg} color="orange" />}
                        <Button
                            label="Save"
                            color="green"
                            type="submit"
                            disabled={isVerified !== null || loading}
                            loading={loading}
                        />
                    </form>
                </div>
            </Paper>
        </Page>
    );
}
