import { useState, useCallback } from "react";

const useFormInputs = (initial = {}) => {
    const [inputs, setInputs] = useState(initial);
    const [errorMsg, setErrorMsg] = useState("");

    const handleInputChange = useCallback((event) => {
        if (!event.target) {
            //input keys and values received as an object
            setInputs(event);
        } else {
            //on input's onChange event
            setErrorMsg("");
            setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
        }
    }, []);

    return [inputs, handleInputChange, errorMsg, (message) => setErrorMsg(message)];
};

export default useFormInputs;
