import Button from "./../Button";
import CloseIcon from "./../../assets/images/icons/close_white.svg";

export default function ConfirmationPopup({ text, title, onConfirm, onCancel, loading }) {
    return (
        <div className="Popup-Wrapper">
            <div className="Popup">
                <div className="Popup-Header">
                    <p>{title}</p>
                    <button className="close" onClick={onCancel} disabled={loading}>
                        <img src={CloseIcon} alt="close icon" />
                    </button>
                </div>
                <div className="Popup-Content">
                    <p>{text}</p>
                    <div className="Popup-Btns">
                        <Button label="No" color="grey" onClick={onCancel} disabled={loading} />
                        <Button
                            label="Yes"
                            color="green"
                            onClick={onConfirm}
                            loading={loading}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
