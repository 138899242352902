import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Page from "./../components/Page";
import Box from "./../components/Box";
import LoginFrom from "./../components/LoginForm";
import imageOne from "./../assets/images/deviceImgs/settings.png";
import imageTwo from "./../assets/images/deviceImgs/profile.png";
import { checkIfLogged } from "../helpers/checkIfLogged";
import paths from "../constants/paths";

export default function Login() {
    const history = useHistory();

    useEffect(() => {
        if (checkIfLogged()) {
            history.push(paths.HOME);
        }
    }, [history]);

    return (
        <Page noNavigation>
            <Box
                title="Welcome to Proxfit"
                text="The Media platform that combines Fitness, Lifestyle and Social Networking"
                animationName="fadeInLeft"
                images={[
                    {
                        src: imageOne,
                        alt: "Proxfit App Screen Settings",
                        style: {
                            width: "40%",
                            position: "relative",
                            left: "5%",
                            bottom: "5%",
                        },
                    },
                    {
                        src: imageTwo,
                        alt: "Proxfit App Screen Profile",
                        style: {
                            width: "40%",
                            position: "relative",
                            top: "5%",
                            right: "5%",
                        },
                    },
                ]}
                onMobHide
            >
                <LoginFrom />
            </Box>
        </Page>
    );
}
