import api from "./APIlibrary";

const API = {
    getFollowers(params) {
        return api.get("users/me/followers", params);
    },
    createPost(params, cancelToken) {
        return api.post("posts", params, cancelToken);
    },
};

export default API;
