import Loader from "./../../assets/images/loader.gif";

export default function Button({
    type = "button",
    label = "",
    onClick,
    color = "",
    loading = false,
    iconSrc = "",
    disabled = false,
    isSmall,
    deleteBtn,
    maxWidth = null,
    ...restProps
}) {
    return (
        <div
            className={["Button", isSmall ? "small" : "", deleteBtn ? "delete-btn" : ""].join(" ")}
            style={{ maxWidth: maxWidth ? maxWidth : "unset" }}
        >
            <button
                type={type}
                className={[
                    color === "orange" ? "orange" : "",
                    color === "green" ? "green" : "",
                    color === "grey" ? "grey" : "",
                    loading ? "loader" : "",
                ].join(" ")}
                onClick={onClick}
                disabled={disabled}
                {...restProps}
            >
                {!loading ? (
                    <span>{label}</span>
                ) : (
                    <img className="loader" src={Loader} alt="loader" />
                )}
                {iconSrc && !loading && <img className="Button-Icon" src={iconSrc} alt="icon" />}
            </button>
        </div>
    );
}
