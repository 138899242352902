export const countries = [
    "au",
    "bh",
    "ca",
    "fr",
    "de",
    "kw",
    "lb",
    "nz",
    "ru",
    "sa",
    "rs",
    "es",
    "tr",
    "ua",
    "ae",
    "uk",
];
