import React, { useState } from "react";
import Page from "./../../components/Page";
import Paper from "./../../components/Paper";
import QrReader from "react-qr-reader";
import API from "../../APIs/scan";
import Error from "./Error";
import Success from "./Success";
import ScanIcon from "./../../assets/images/icons/scan.svg";

export default function Scan() {
    const [isScenned, setIsScenned] = useState(false);
    const [scannError, setScannError] = useState(null);
    const [scannedVoucher, setScannedVocuher] = useState(null);

    const handleScan = (data) => {
        if (data) {
            let voucherData = {
                hash: data,
            };
            redeemVoucher(voucherData);
        }
    };

    const redeemVoucher = async (voucherData) => {
        setIsScenned(true);
        try {
            const res = await API.redeemVoucher(voucherData);
            setScannError(null);
            setScannedVocuher(res.data);
        } catch (error) {
            console.log("error -> ", error);
            setScannError(error);
        }
    };

    const handleError = (err) => {
        console.error(err);
    };
    return (
        <Page>
            <Paper title="Scan Voucher" icon={ScanIcon} iconBackground="orange">
                <div className="Scan">
                    {!isScenned && (
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            className="Scan-Window"
                        />
                    )}
                    {isScenned && scannError && (
                        <Error
                            errorCode={scannError}
                            onScanAgain={() => {
                                setIsScenned(false);
                                setScannError(null);
                            }}
                        />
                    )}
                    {isScenned && scannedVoucher && (
                        <Success
                            voucherData={scannedVoucher}
                            onScanAgain={() => {
                                setIsScenned(false);
                                setScannedVocuher(null);
                            }}
                        />
                    )}
                </div>
            </Paper>
        </Page>
    );
}
