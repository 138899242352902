export const reducer = (state, action) => {
    switch (action.type) {
        case "ERROR":
            return {
                ...state,
                errorMsg: action.errorMsg,
                loading: false,
            };
        case "CREATE_NEW_POST":
            return {
                ...state,
                newPost: {
                    ...state.newPost,
                    title: action.formData.title,
                    description: action.formData.description,
                    tags: action.formData.tags,
                },
                loading: action.loading,
                errorMsg: "",
            };
        case "SET_IMAGE":
            return {
                ...state,
                newPost: {
                    ...state.newPost,
                    isimage: 1,
                    media_offset: { ratio: 0, y: 0, x: 0 },
                },
                errorMsg: "",
                croppedBlob: action.value,
            };
        case "SET_VIDEO":
            return {
                ...state,
                newPost: {
                    ...state.newPost,
                    isimage: 0,
                    media_offset: {
                        ratio: action.videoData.ratio,
                        y: action.videoData.y,
                        x: action.videoData.x,
                    },
                },
                errorMsg: "",
                croppedBlob: action.value,
            };
        default:
            return state;
    }
};
