import React, { useState, useEffect } from "react";
import Page from "./../components/Page";
import Box from "./../components/Box";
import phoneImage from "./../assets/images/deviceImgs/splash.png";
import UserPhoneNumber from "../components/UserPhoneNum";
import DotsProgress from "../components/DotsProgress";
import CodeInput from "../components/CodeInput";
import ResetPassword from "../components/ResetPassword";
import { ForgottenPasswordProvider } from "../contexts/passwordContext";
const stepDescriptions = [
    "",
    "Please enter your username and phone number. We will send you a code to reset your password.",
    "Type in 4-digit verification number we sent to your phone number.",
    "Please enter your new password in the following fields and press Reset",
];

export default function ForgotPassword() {
    const [step, setStep] = useState(1);
    const [text, setText] = useState(stepDescriptions[step]);
    useEffect(() => {
        setText(stepDescriptions[step]);
    }, [step]);
    return (
        <Page noNavigation>
            <Box
                title="Reset Password"
                text={text}
                animationName="fadeInRight"
                images={[
                    {
                        src: phoneImage,
                        alt: "Proxfit App Splash Screen",
                        style: {
                            width: "60%",
                        },
                    },
                ]}
                onMobHide
                returnComponent={() => <DotsProgress numOfSteps={3} current={step} />}
            >
                <ForgottenPasswordProvider>
                    <div style={{ height: "250px", width: "100%" }}>
                        {step === 1 && <UserPhoneNumber onNext={() => setStep(step + 1)} />}
                        {step === 2 && <CodeInput onNext={() => setStep(step + 1)} />}
                        {step === 3 && <ResetPassword />}
                    </div>
                </ForgottenPasswordProvider>
            </Box>
        </Page>
    );
}
