import React from "react";
import TabMenu from "./TabMenu";
import Item from "./Item";
import NoResults from "./../NoResults";

export default function VoucherList({
    setVoucherForDrawer,
    vouchers = [],
    getVouchers,
    filter = "",
    noVouchers,
}) {
    return (
        <div className="Voucher-List">
            <TabMenu onClickEvent={(filter) => getVouchers(filter)} />
            <div className="Voucher-List-Content">
                {vouchers &&
                    vouchers.map((voucher, i) => {
                        return (
                            <Item
                                key={i}
                                voucher={voucher}
                                onClickEvent={() => setVoucherForDrawer(voucher)}
                            />
                        );
                    })}
            </div>
            {vouchers && vouchers.length === 0 && noVouchers ? (
                <NoResults
                    message={`Your ${
                        filter === "all" || !filter ? "" : filter
                    } vouchers will be here...`}
                />
            ) : null}
        </div>
    );
}
