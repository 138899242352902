import api from "./APIlibrary";

const API = {
    getPosts(params) {
        return api.get("posts", params);
    },
    getPostDetails(post_id, params) {
        return api.get(`posts/${post_id}`, params);
    },
    getPostLikes(post_id, params) {
        return api.get(`posts/${post_id}/likes`, params);
    },
    getPostComments(post_id, params) {
        return api.get(`posts/${post_id}/comments`, params);
    },
    deletePost(post_id, params) {
        return api.delete(`posts/${post_id}`, params);
    },
    getCommentReplies(comment_id, params) {
        return api.get(`posts/comments/${comment_id}/replies`, params);
    },
    addComment(post_id, params) {
        return api.post(`posts/${post_id}/comments`, params);
    },
    deleteComment(comment_id, params) {
        return api.delete(`posts/comments/${comment_id}`, params);
    },
};

export default API;
