import React, { useState, useEffect, createRef, useRef, useCallback } from "react";
import Page from "./../components/Page";
import Paper from "./../components/Paper";
import PostsIcon from "./../assets/images/icons/posts.svg";
import PostList from "./../components/PostList";
import SideDrawer from "./../components/SideDrawer";
import PostView from "./../components/PostView";
import API from "../APIs/posts";
import ConfirmationPopup from "./../components/ConfirmationPopup";
import LoadMore from "./../components/LoadMore";
import usePopup from "./../customHooks/usePopup";
import useDrawer from "../customHooks/useDisplayableItem";
import requsetErrorMessage from "./../helpers/requsetErrorMessage";

export default function Posts() {
    const [allPosts, setAllPosts] = useState([]);
    const [postForView, setPostForView] = useState({});
    const drawerRef = createRef();
    const [showDrawer, setShowDrawer] = useDrawer(false, drawerRef, ["Item", "Popup-Wrapper"]);
    const [popup, setPopup] = usePopup(false);
    const [loading, setLoading] = useState(false);
    const fromID = useRef(null);
    const [removeLoader, setRemoveLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [noPosts, setNoPosts] = useState(null);

    const getPosts = useCallback(async () => {
        let queryObj = {
            params: {
                ...(fromID.current && { fromid: fromID.current }),
                limit: 9,
            },
        };

        try {
            const res = await API.getPosts(queryObj);
            if (res.data.posts.length === 0) {
                setNoPosts(true);
            }
            if (res.data.posts.length <= 8) {
                setRemoveLoader(true);
            }
            setAllPosts((allPosts) => [...allPosts, ...res.data.posts]);
        } catch (error) {
            console.log("error -> ", error);
        }
    }, []);

    useEffect(() => {
        fromID.current = allPosts.length !== 0 && allPosts[allPosts.length - 1].id;
    }, [allPosts]);

    const setPostForDrawerHandler = async (id) => {
        errorMsg && setErrorMsg("");
        try {
            const res = await API.getPostDetails(id);
            setPostForView({});
            setPostForView(res.data.post);
            setShowDrawer(true);
        } catch (error) {
            console.log("error -> ", error);
        }
    };

    const deletePostHandler = async () => {
        errorMsg && setErrorMsg("");
        setLoading(true);
        try {
            const res = await API.deletePost(postForView.id);
            setLoading(false);
            setPopup(false);
            if (res.data.success) {
                onDeletedPostHandler(postForView.id);
            }
        } catch (error) {
            console.log("error -> ", error);
            setLoading(false);
            setPopup(false);
            setErrorMsg(requsetErrorMessage(error));
        }
    };

    const onDeletedPostHandler = (postID) => {
        setShowDrawer(false);
        let refreshPosts = [];
        allPosts.forEach((post) => {
            if (post.id !== postID) {
                refreshPosts.push(post);
            }
        });
        setAllPosts([]);
        setAllPosts(refreshPosts);
    };

    return (
        <Page>
            <Paper title="Posts" icon={PostsIcon} iconBackground="green">
                <PostList
                    posts={allPosts}
                    setPostForDrawer={(post) => setPostForDrawerHandler(post.id)}
                    noPosts={noPosts}
                />
                {!removeLoader && <LoadMore onLoad={getPosts} />}
            </Paper>
            <SideDrawer
                ref={drawerRef}
                visible={showDrawer}
                hideDrawer={() => setShowDrawer(false)}
            >
                <PostView
                    post={postForView}
                    onDeletedPost={() => setPopup(true)}
                    errorMsg={errorMsg}
                />
            </SideDrawer>
            {popup && (
                <ConfirmationPopup
                    title="Delete Post"
                    text="Are you shure you want to delete post?"
                    onCancel={() => setPopup(false)}
                    onConfirm={deletePostHandler}
                    loading={loading}
                />
            )}
        </Page>
    );
}
