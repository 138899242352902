const errorCodes = {
    // Profile Update Errors
    invalid_working_hours:
        'Please check working hours. Make sure that both "From" and "To" are selecetd.',
    invalid_phone: "Invalid phone number.",
    //Login Errors
    login_error: "Invalid Username or Password.",
    missing_username: "Username required!",
    //Post Errors
    post_not_exists: "Oops! This post might have been reoved or disabled.",
    //New Vocuher
    payout_email_not_verified: "Voucher not created. Your Bank Account details are not verified.",
    //Scan
    no_redeem_permission: "Seems like you scanned someone else's QR code",
    already_used: "This voucher is alreay used",
    //forgottern password
    user_not_exists: "Can't find a user",
    phone_not_matching: "Phone number is not connected to your account",
    reset_pass_cooldown: "You can only reset the password once every 24 hours",
    //sms code
    incorrect_verification_code: "Wrong verification code!",
    missing_verification_code: "Missing verification code!",
    resend_code_cooldown_long: "You must wait 24h in order to receive a new code",
    //new password
    invalid_password: "New password should contain atleast 6 characters",
};

export default errorCodes;
