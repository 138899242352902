import React, { useState, createRef, useEffect, useRef, useCallback } from "react";
import Page from "./../components/Page";
import Paper from "./../components/Paper";
import VoucherIcon from "./../assets/images/icons/voucher.svg";
import VoucherList from "./../components/VoucherList.js";
import SideDrawer from "./../components/SideDrawer";
import VoucherView from "./../components/VoucherView";
import API from "../APIs/vouchers";
import ConfirmationPopup from "./../components/ConfirmationPopup";
import LoadMore from "./../components/LoadMore";
import usePopup from "./../customHooks/usePopup";
import useDrawer from "./../customHooks/useDisplayableItem";
import requsetErrorMessage from "./../helpers/requsetErrorMessage";

export default function Vouchers() {
    const [allVouchers, setAllVouchers] = useState([]);
    const [filterName, setFilterName] = useState(null);
    const drawerRef = createRef();
    const [showDrawer, setShowDrawer] = useDrawer(false, drawerRef, ["Item", "Popup-Wrapper"]);
    const [voucherForView, setVoucherForView] = useState({});
    const [popup, setPopup] = usePopup(false);
    const fromID = useRef(null);
    const [removeLoader, setRemoveLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [noVouchers, setNoVouchers] = useState(null);

    const getVouchers = useCallback(
        async (filter) => {
            if (filter) {
                fromID.current = null;
            }
            let queryObj = {
                params: {
                    filter: filterName,
                    limit: 9,
                    ...(fromID.current && { fromid: fromID.current }),
                },
            };
            try {
                const res = await API.getVouchers(queryObj);
                if (res.data.vouchers.length === 0) {
                    setNoVouchers(true);
                }
                if (res.data.vouchers.length <= 8) {
                    setRemoveLoader(true);
                } else {
                    setRemoveLoader(false);
                }
                if (filter) {
                    setAllVouchers([...res.data.vouchers]);
                } else {
                    setAllVouchers((allVouchers) => [...allVouchers, ...res.data.vouchers]);
                }
            } catch (error) {
                console.log("error -> ", error);
            }
        },
        [filterName],
    );

    useEffect(() => {
        if (filterName !== null) {
            getVouchers(true);
        }
    }, [filterName, getVouchers]);

    useEffect(() => {
        fromID.current = allVouchers.length !== 0 && allVouchers[allVouchers.length - 1].id;
    }, [allVouchers]);

    const cancelVoucherHandler = async () => {
        errorMsg && setErrorMsg("");
        setLoading(true);
        try {
            await API.cancelVoucher(voucherForView.id);
            setLoading(false);
            setPopup(false);
            setShowDrawer(false);
            getVouchers(true);
        } catch (error) {
            console.log("error -> ", error);
            setLoading(false);
            setPopup(false);
            setErrorMsg(requsetErrorMessage(error));
        }
    };

    return (
        <Page>
            <Paper title="Vouchers" icon={VoucherIcon} iconBackground="green">
                <VoucherList
                    vouchers={allVouchers}
                    setVoucherForDrawer={(voucher) => {
                        setVoucherForView(voucher);
                        setShowDrawer(true);
                        errorMsg && setErrorMsg("");
                    }}
                    getVouchers={(filterName) => setFilterName(filterName)}
                    filter={filterName}
                    noVouchers={noVouchers}
                />
                {!removeLoader && <LoadMore onLoad={getVouchers} />}
            </Paper>
            <SideDrawer
                ref={drawerRef}
                visible={showDrawer}
                hideDrawer={() => setShowDrawer(false)}
            >
                <VoucherView
                    voucher={voucherForView}
                    onCancelVoucher={() => setPopup(true)}
                    errorMsg={errorMsg}
                />
            </SideDrawer>
            {popup && (
                <ConfirmationPopup
                    title="Cancel Voucher"
                    text="Are you shure you want to cancel this voucher?"
                    onCancel={() => setPopup(false)}
                    onConfirm={cancelVoucherHandler}
                    loading={loading}
                />
            )}
        </Page>
    );
}
