import React from "react";
import imageLogo from "./../../assets/images/logo/proxfit_white_simbol.svg";
import ScrollAnimation from "react-animate-on-scroll";

export default function Box({
    isRowReverse = false,
    title = "",
    text = "",
    images = [{ src: "", alt: "", style: {} }],
    animationName = "",
    onMobHide = false,
    returnComponent,
    children,
}) {
    return (
        <ScrollAnimation animateIn={animationName} animateOnce>
            <div className={`Box ${isRowReverse ? "reverse" : ""}`}>
                <div className={`Box-Left ${onMobHide ? "hideOnMob" : ""}`}>
                    {images.map((image, i) => {
                        return <img key={i} src={image.src} alt={image.alt} style={image.style} />;
                    })}
                </div>
                <div className="Box-Right">
                    <div className="Box-Right-Content">
                        <img className="logo" src={imageLogo} alt="Proxfit Logo" />
                        <p className="title">{title}</p>
                        {returnComponent && returnComponent()}
                        <p className="text">{text}</p>
                        {children}
                    </div>
                </div>
            </div>
        </ScrollAnimation>
    );
}
