import React, { useState, useEffect, createRef, useCallback } from "react";
import LikeIcon from "./../../assets/images/icons/like.svg";
import CommentIcon from "./../../assets/images/icons/comment.svg";
import DeleteIcon from "./../../assets/images/icons/delete.svg";
import TaggedList from "./TaggedList";
import LikesList from "./LikesList";
import Input from "./../Input";
import Comments from "./Comments";
import API from "../../APIs/posts";
import ProfileIcon from "./../../assets/images/icons/profile.svg";
import ErrorMsg from "../ErrorMsg";
import { getX, getY } from "./../../helpers/getVideoOffsets";

export default function PostView({ post = {}, onDeletedPost, errorMsg }) {
    const [taggedList, setTaggedList] = useState(false);
    const [likesList, setLikesList] = useState(false);
    const [commentsList, setCommentsList] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentsNum, setCommentsNum] = useState(0);
    const [likes, setLikes] = useState([]);
    const commentRef = createRef();
    const [comment, setComment] = useState("");

    const getComments = useCallback(async () => {
        try {
            const res = await API.getPostComments(post.id);
            setComments(res.data.comments);
        } catch (error) {
            console.log("error -> ", error);
        }
    }, [post.id]);

    useEffect(() => {
        if (post.id) {
            setCommentsList(false);
            setTaggedList(false);
            setLikesList(false);
            setCommentsNum(post.comments_num);
            const getLikes = async () => {
                try {
                    const res = await API.getPostLikes(post.id);
                    setLikes(res.data);
                } catch (error) {
                    console.log("error -> ", error);
                }
            };
            getComments();
            getLikes();
        }
    }, [post, getComments]);

    const setTaggedListHandler = () => {
        if (post.tagged_users.length === 0) {
            return;
        }
        if (commentsList) {
            setCommentsList(false);
        }
        if (likesList) {
            setLikesList(false);
        }
        setTaggedList(!taggedList);
    };

    const setLikesListHandler = () => {
        if (post.likes_num === 0) {
            return;
        }
        if (commentsList) {
            setCommentsList(false);
        }
        if (taggedList) {
            setTaggedList(false);
        }
        setLikesList(!likesList);
    };

    const setCommentsListHandler = () => {
        if (commentsNum === 0) {
            return;
        }
        if (taggedList) {
            setTaggedList(false);
        }
        if (likesList) {
            setLikesList(false);
        }
        setCommentsList(!commentsList);
    };

    const addCommentHandler = async (commentID, text) => {
        let newComment = {};
        let isReply = commentID ? true : false;
        if (isReply) {
            newComment = {
                replyTo: commentID,
                text: text,
            };
        } else {
            newComment = {
                text: comment,
            };
        }
        try {
            await API.addComment(post.id, newComment);
            onAddedComment(isReply);
        } catch (error) {
            console.log("error -> ", error);
        }
    };

    const onAddedComment = (isReply) => {
        comment && setComment("");
        !isReply && setCommentsNum(commentsNum + 1);
        getComments();
    };

    const onDeletedComment = (isReply) => {
        !isReply && setCommentsNum(commentsNum - 1);
        getComments();
    };

    return (
        <div className="Post-View">
            <div className="img-wrapper">
                {post.is_image ? (
                    <img src={post.media_url} alt="post media" />
                ) : (
                    post.media_offset && (
                        <video
                            controls
                            src={`https://res.cloudinary.com/proxfit/video/upload/w_720,h_720,c_fill,x_${getX(
                                post.media_offset,
                                720,
                            )},y_${getY(post.media_offset, 720)},q_auto:eco/${
                                post.media_url.split("upload/")[1]
                            }`}
                            type="video/mp4"
                        >
                            Your browser does not support the video tag.
                        </video>
                    )
                )}
            </div>
            <p className="title">{post && post.title}</p>
            <p className="description">{post && post.description}</p>
            {post && post.tagged_users && post.tagged_users.length !== 0 && (
                <div className="tags" title="Tagged" onClick={setTaggedListHandler}>
                    {post &&
                        post.tagged_users.map((t, i) => {
                            return (
                                <div key={i} className="avatar">
                                    {t.avatar_url ? (
                                        <img src={t.avatar_url} alt="Profile Avatar" />
                                    ) : (
                                        <img
                                            className="icon"
                                            src={ProfileIcon}
                                            alt="Profile Avatar"
                                        />
                                    )}
                                </div>
                            );
                        })}
                </div>
            )}
            <hr className="divider"></hr>
            <div className="social">
                <div
                    className={`likes ${post && post.likes_num === 0 ? "disbled" : ""}`}
                    onClick={setLikesListHandler}
                    title={post && post.likes_num === 0 ? "No Likes" : ""}
                >
                    <img src={LikeIcon} alt="Like Icon" />
                    <span>{post && post.likes_num !== 0 && post.likes_num}</span>
                    <span>Likes</span>
                </div>
                <div
                    className={`comments ${commentsNum === 0 ? "disbled" : ""}`}
                    onClick={setCommentsListHandler}
                    title={commentsNum === 0 ? "No Comments" : ""}
                >
                    <img src={CommentIcon} alt="Comment Icon" />
                    <span>{commentsNum !== 0 && commentsNum}</span>
                    <span>Comments</span>
                </div>
                <div className="delete" title="Delete Post" onClick={onDeletedPost}>
                    <img src={DeleteIcon} alt="Delete Icon" />
                    <span>Delete</span>
                </div>
            </div>
            <hr className="divider"></hr>
            {errorMsg && <ErrorMsg message={errorMsg} color="orange" />}
            <Input
                ref={commentRef}
                isTextarea
                maxLenght="250"
                hasCounter={comment}
                placeholder="Add a comment..."
                noBorder
                hasButton
                buttonLabel="Post"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onClickEvent={() => addCommentHandler()}
            />
            <div className="list">
                {post && post.tagged_users && post.tagged_users.length !== 0 && (
                    <TaggedList
                        visible={taggedList}
                        list={post.tagged_users}
                        onClickEvent={() => setTaggedList(false)}
                    />
                )}
                {likes.length !== 0 && (
                    <LikesList
                        visible={likesList}
                        list={likes}
                        onClickEvent={() => setLikesList(false)}
                    />
                )}
                {comments.length !== 0 && (
                    <Comments
                        visible={commentsList}
                        list={comments}
                        onClickEvent={() => setCommentsList(false)}
                        addReply={(id, reply) => addCommentHandler(id, reply)}
                        deleteComment={(isReply) => onDeletedComment(isReply)}
                    />
                )}
            </div>
        </div>
    );
}
