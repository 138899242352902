import imageLogo from "./../../assets/images/logo/proxfit_green_full.svg";
import { Link } from "react-router-dom";
import { FaYoutube, FaApple, FaGooglePlay } from "react-icons/fa";
import useLoginCheck from "./../../customHooks/useLoginCheck";

export default function Footer() {
    const userLoggedIn = useLoginCheck();

    return (
        <div className="Footer">
            <div className="Footer-Row">
                <div className="col">
                    <img className="logo" src={imageLogo} alt="Proxfit Logo" />
                </div>
                <div className="col">
                    <p className="title">Contact</p>
                    <a
                        className="link"
                        href="https://proxfit.com/home#enquiries"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Enquiries
                    </a>
                    <a
                        className="link"
                        href="https://proxfit.com/support/#contact-support"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Support
                    </a>
                </div>
                {userLoggedIn && (
                    <div className="col">
                        <p className="title">Proxfit business</p>
                        <Link className="link" to="/profile">
                            My Profile
                        </Link>
                        <Link className="link" to="/newVoucher">
                            Create Voucher
                        </Link>
                        <Link className="link" to="/newPost">
                            Create Post
                        </Link>
                        <Link className="link" to="/scan">
                            Scan Voucher
                        </Link>
                    </div>
                )}
                <div className="col">
                    <p className="title">Visit Proxfit</p>
                    <div className="visit">
                        <a
                            className="link"
                            href="https://play.google.com/store/apps/details?id=com.proxfit"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaGooglePlay />
                        </a>
                        <a
                            className="link"
                            href="https://apps.apple.com/hr/app/proxfit/id1439232267"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaApple />
                        </a>
                        <a
                            className="link"
                            href="https://www.youtube.com/channel/UCpS7vc5Yap74Z73vJl5QT7w"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaYoutube />
                        </a>
                    </div>
                </div>
            </div>
            <div className="Footer-Bottom">
                © ABALDA Technologies INC 2021. All rights reserved.
            </div>
        </div>
    );
}
