import axios from "axios";

// intercept each request
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (401 === error.response.status) {
            window.location.replace("/login");
        } else {
            return Promise.reject(error.response.data.error);
        }
    },
);

const apiLib = {
    get(address, params) {
        return axios.get(`/webapi/${address}`, params);
    },
    post(address, data, cancelToken) {
        return axios.post(`/webapi/${address}`, data, cancelToken);
    },
    delete(address) {
        return axios.delete(`/webapi/${address}`);
    },
    patch(address, data) {
        return axios.patch(`/webapi/${address}`, data);
    },
    put(address, data) {
        return axios.put(`/webapi/${address}`, data);
    },
};

export default apiLib;
