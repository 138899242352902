import ProfileInfo from "./../../components/ProfileInfo";
import { formatDate } from "../../helpers/formatDate";
import StarRatings from "react-star-ratings";

export default function Review({ review }) {
    return (
        <div className="Review">
            <div className="Review-Row">
                <ProfileInfo
                    username={review && review.user && review.user.username}
                    name={review && review.user && review.user.name}
                    avatarSrc={review && review.user && review.user.avatar_url}
                />
                <div className="rate">
                    <div className="stars">
                        <StarRatings
                            rating={review && review.rate}
                            starRatedColor="#00be82"
                            starEmptyColor="#9ba0a4"
                            numberOfStars={5}
                            starDimension="20px"
                            starSpacing="0"
                        />
                    </div>
                    <span className="date">
                        {review.created_at && formatDate(new Date(review.created_at))}
                    </span>
                </div>
            </div>
            {review && review.text && <p className="text">{review.text}</p>}
        </div>
    );
}
