import TimeKeeper from "react-timekeeper";
import React, { forwardRef } from "react";

const Time = forwardRef(({ time, onChange, closeTime }, ref) => {
    return (
        <div className="Time-wrapper">
            <div ref={ref}>
                <TimeKeeper
                    time={time === "" ? "00:00" : time}
                    hour24Mode
                    onChange={(data) => onChange(data.formatted24)}
                />
                <div className="bottom">
                    <button type="button" onClick={closeTime}>
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );
});

export default Time;
