import api from "./APIlibrary";

const API = {
    getProfile(params) {
        return api.get(`users/me`, params);
    },
    updateProfile(params) {
        return api.patch("services", params);
    },
    getReviews(params) {
        return api.get(`services/me/reviews`, params);
    },
};

export default API;
