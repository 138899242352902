import createStore from "../store";
import { reducer } from "../reducers/passwordReducer";

const initialState = {
    username: "",
    phone: "",
    hash: "",
};

const [
    ForgottenPasswordProvider,
    useForgottenPasswordStore,
    useForgottenPasswordDispatch,
] = createStore(reducer, initialState);

export { ForgottenPasswordProvider, useForgottenPasswordStore, useForgottenPasswordDispatch };
