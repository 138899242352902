import React, { useState, useEffect, createRef } from "react";
import Page from "./../../components/Page";
import Button from "./../../components/Button";
import Input from "./../../components/Input";
import WarningMsg from "./../../components/warningMsg";
import Paper from "./../../components/Paper";
import ProfileIcon from "./../../assets/images/icons/profile.svg";
import AvatarIcon from "./../../assets/images/icons/avatar.svg";
import UploadIcon from "./../../assets/images/icons/image_green.svg";
import ClearIcon from "./../../assets/images/icons/close_green_gradient.svg";
import WorkingHours from "./../../components/WorkingHours";
import API from "../../APIs/profile";
import { useForm } from "react-hook-form";
import ErrorMsg from "./../../components/ErrorMsg";
import requsetErrorMessage from "./../../helpers/requsetErrorMessage";
import useFormInputs from "./../../customHooks/useFormInputs";
import SideDrawer from "./../../components/SideDrawer";
import useDrawer from "./../../customHooks/useDisplayableItem";
import Reviews from "./Reviews";
import Map from "../../components/Map";
import ImageCropper from "../../components/ImageCropper";

export default function Profile() {
    const [userDetails, setuserDetails] = useState({ name: "", username: "", rating: "" });
    const [service, setService, errorMsg, setErrorMsg] = useFormInputs({
        avatarurl: "",
        address: "",
        lat: "",
        lng: "",
        description: "",
        phone: "",
        categories: "",
    });
    const [edit, setEdit] = useState(false);
    const [workingHours, setWorkingHours] = useState({
        sunday: {},
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm({ criteriaMode: "all" });
    const descriptionRef = createRef();
    const drawerRef = createRef();
    const [showDrawer, setShowDrawer] = useDrawer(false, drawerRef, ["address"]);
    const [map, setMap] = useState(false);
    const imageFileRef = React.createRef();
    const [croppedBlob, setCroppedBlob] = useState(null);

    useEffect(() => {
        const getProfile = async () => {
            try {
                const res = await API.getProfile();
                setService({
                    avatarurl: res.data.service.avatar_url,
                    address: res.data.service.address ? res.data.service.address : "",
                    description: res.data.service.description ? res.data.service.description : "",
                    phone: res.data.service.phone ? res.data.service.phone : "",
                    lat: res.data.service.lat,
                    lng: res.data.service.lng,
                    categories: res.data.service.categories,
                });
                setuserDetails({
                    name: res.data.name,
                    username: res.data.username,
                    rating: res.data.service.rating,
                });
                setWorkingHours(res.data.service.working_hours);
            } catch (error) {
                console.log("error -> ", error);
            }
        };
        if (!edit) {
            getProfile();
        }
    }, [setService, edit]);

    const onChangeHoursHandler = (day, hours) => {
        if (errorMsg) {
            setErrorMsg("");
        }
        setWorkingHours({
            ...workingHours,
            [day]: hours,
        });
    };

    const handleEditProfile = async () => {
        setLoading(true);
        try {
            let postParams = {
                ...service,
                workinghours: workingHours,
            };

            if (croppedBlob) {
                const signatureAjax = await fetch(
                    `/webapi/uploadsignature?timestamp=${Date.now()}`,
                    {
                        method: "POST",
                    },
                );
                const { api_key, signature, timestamp, upload_url } = await signatureAjax.json();

                const reader = new FileReader();
                reader.onloadend = async function () {
                    const base64data = reader.result;

                    const formData = new FormData();
                    formData.append("file", base64data);
                    formData.append("api_key", api_key);
                    formData.append("timestamp", timestamp);
                    formData.append("signature", signature);
                    const uploadAjax = await fetch(upload_url, { method: "POST", body: formData });
                    const { secure_url } = await uploadAjax.json();

                    try {
                        postParams = { ...postParams, ...{ avatarurl: secure_url } };

                        await API.updateProfile(postParams);
                        setLoading(false);
                        setEdit(false);
                    } catch (error) {
                        setLoading(false);
                        setErrorMsg(requsetErrorMessage(error && error));
                    }
                };
                reader.readAsDataURL(croppedBlob);
            } else {
                await API.updateProfile(postParams);
                setLoading(false);
                setEdit(false);
            }
        } catch (error) {
            setLoading(false);
            setErrorMsg(requsetErrorMessage(error && error));
        }
    };

    const profileImageSource = croppedBlob
        ? URL.createObjectURL(croppedBlob)
        : service.avatarurl
        ? service.avatarurl
        : AvatarIcon;

    return (
        <Page>
            <Paper
                title="My Profile"
                icon={ProfileIcon}
                iconBackground="orange"
                rating={userDetails && userDetails.rating && userDetails.rating.toFixed(1)}
                onRatingClick={() => {
                    setMap(false);
                    setShowDrawer(true);
                }}
            >
                <form className="Profile" onSubmit={handleSubmit(handleEditProfile)}>
                    <div className="Profile-Col">
                        <div className="Profile-Col-Avatar">
                            <div className="image">
                                <ImageCropper
                                    isVisible={false}
                                    ref={imageFileRef}
                                    blobCropped={(blob) => {
                                        setCroppedBlob(blob);
                                    }}
                                />
                                <img src={profileImageSource} alt="avatar icon" />
                                {edit && !loading && (croppedBlob || service.avatarurl) && (
                                    <img
                                        className="clear-icon"
                                        src={ClearIcon}
                                        alt="clear icon"
                                        onClick={() => {
                                            setService({ ...service, ...{ avatarurl: "" } });
                                            setCroppedBlob(null);
                                        }}
                                        title="Remove Avatar"
                                    />
                                )}
                                {edit && !loading && (
                                    <img
                                        className="upload-icon"
                                        src={UploadIcon}
                                        alt="upload icon"
                                        onClick={() => imageFileRef.current.click()}
                                        title="Upload Avatar"
                                    />
                                )}
                            </div>
                            <div className={`info ${!userDetails.name ? "placeholder" : ""}`}>
                                <p className="name">{userDetails.name}</p>
                                <p className="username">{userDetails.username}</p>
                                {edit ? (
                                    <Button
                                        label="Save"
                                        color="green"
                                        type="submit"
                                        loading={loading}
                                        disabled={loading}
                                    />
                                ) : (
                                    <Button
                                        label="Edit Profile"
                                        color="green"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEdit(true);
                                        }}
                                    />
                                )}
                                {edit && !loading && (
                                    <Button
                                        label="Cancel"
                                        color="orange"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEdit(false);
                                        }}
                                    />
                                )}
                                {errorMsg && <ErrorMsg message={errorMsg} color="orange" />}
                            </div>
                        </div>
                        <Input
                            disabled={!edit || loading}
                            label="Address"
                            name="address"
                            value={service.address}
                            onChange={setService}
                            errors={errors}
                            maxLenght="120"
                            ref={register({
                                required: "Required",
                                minLength: {
                                    value: 6,
                                    message: "Address must be from 6 to 120 characters",
                                },
                            })}
                            onFocus={() => {
                                setShowDrawer(true);
                                setMap(true);
                            }}
                            className="address"
                        />
                        <Input
                            disabled={!edit || loading}
                            label="Phone Number"
                            type="tel"
                            name="phone"
                            defaultValue={service.phone}
                            onChange={setService}
                            errors={errors}
                            maxLenght="16"
                            ref={register({
                                required: "Required",
                                pattern: {
                                    value: /^\+\d+$/,
                                    message: "Invalid phone number. Example: +1123456789",
                                },
                                minLength: {
                                    value: 8,
                                    message: "Phone number must be at least 8 characters",
                                },
                            })}
                        />
                        <Input
                            disabled={!edit || loading}
                            label="About"
                            isTextarea
                            ref={descriptionRef}
                            hasCounter={edit}
                            maxLenght="120"
                            name="description"
                            defaultValue={service.description}
                            onChange={setService}
                        />
                    </div>
                    <div className="Profile-Col">
                        <div className="Profile-Col-Hours">
                            <p className="title">Working Hours</p>
                            <p className="subtitle">Set your working hours and availability</p>
                            {workingHours &&
                                Object.keys(workingHours).map((day, i) => {
                                    return (
                                        <WorkingHours
                                            key={i}
                                            disabled={!edit || loading}
                                            dayName={day}
                                            hours={workingHours[day]}
                                            onChangeHours={(hours) =>
                                                onChangeHoursHandler(day, hours)
                                            }
                                        />
                                    );
                                })}
                        </div>

                        <WarningMsg text="Please be aware that the Business Name and Business Category are pieces of information that can be changed by contacting our support." />
                    </div>
                </form>
            </Paper>
            <SideDrawer
                ref={drawerRef}
                visible={showDrawer}
                hideDrawer={() => setShowDrawer(false)}
            >
                {showDrawer && !map && <Reviews />}
                {showDrawer && map && (
                    <Map
                        coordinates={{ lat: service.lat, lng: service.lng }}
                        address={service.address}
                        onAddressChange={(address, newCoordinates) => {
                            setService({
                                ...service,
                                address: address,
                                lat: newCoordinates.lat,
                                lng: newCoordinates.lng,
                            });
                        }}
                    />
                )}
            </SideDrawer>
        </Page>
    );
}
