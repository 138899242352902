import React, { useState, useEffect } from "react";
import PurchaseIcon from "./../../assets/images/icons/purchases.svg";
import DeleteIcon from "./../../assets/images/icons/delete.svg";
import Purchases from "./Purchases";
import { formatDate } from "./../../helpers/formatDate";
import API from "../../APIs/vouchers";
import Button from "./../Button";
import ErrorMsg from "../ErrorMsg";
import ImageIcon from "../../assets/images/icons/image.svg";

export default function VoucherView({
    voucher = {},
    onCancelVoucher,
    isPreview = false,
    totalPrice = null,
    errorMsg,
    isCancelAvailable = true,
    blob,
}) {
    const [purchaseList, setPurchaseList] = useState(false);
    const [purchases, setPurchases] = useState([]);

    const setPurchaseListHandler = () => {
        if (purchases.length === 0) {
            return;
        }
        setPurchaseList(!purchaseList);
    };

    useEffect(() => {
        if (voucher.id) {
            setPurchaseList(false);
            const getPurchases = async () => {
                try {
                    const res = await API.getVoucherPurchases(voucher.id);
                    setPurchases(res.data.purchases);
                } catch (error) {
                    console.log("error -> ", error);
                }
            };
            getPurchases();
        }
    }, [voucher.id]);

    return (
        <div className="Voucher-View">
            <div className="img-wrapper">
                {blob ? (
                    <img src={URL.createObjectURL(blob)} alt="voucher" />
                ) : voucher.image ? (
                    <img src={voucher.image} alt="voucher" />
                ) : (
                    <div className="placeholder">
                        <img src={ImageIcon} alt="gallery icon" />
                    </div>
                )}
                <div className="discount">
                    <span>{voucher.discount}%</span>
                </div>
                {!isPreview ? (
                    <div
                        className={[
                            "bottom",
                            voucher.status === "active" ? "active" : "",
                            voucher.status === "unlisted" || voucher.status === "expired"
                                ? "expired"
                                : "",
                            voucher.status === "cancelled" ? "cancelled" : "",
                            voucher.status === "sold" ? "sold" : "",
                        ].join(" ")}
                    >
                        <p>
                            {voucher.status === "active"
                                ? `${voucher.in_stock} in stock`
                                : voucher.status === "unlisted" || voucher.status === "expired"
                                ? "Unlisted"
                                : voucher.status === "cancelled"
                                ? "Cancelled"
                                : voucher.status === "sold"
                                ? "Sold-out"
                                : ""}
                        </p>
                    </div>
                ) : (
                    <div className="bottom active">
                        <p>{voucher.quantity + " in stock"}</p>
                    </div>
                )}
            </div>
            <p className="title">{voucher.title}</p>
            <div className="price">
                <span className="price-original">{voucher.price}</span>
                <span className="price-discount">
                    {isPreview ? totalPrice : voucher.totalPrice && voucher.totalPrice.toFixed(3)}{" "}
                    BHD
                </span>
            </div>
            <hr className="divider"></hr>
            {voucher.description && (
                <div className="prop">
                    <p className="prop-label">Description</p>
                    <p className="prop-value">{voucher.description}</p>
                    <hr className="divider"></hr>
                </div>
            )}
            {voucher.available_till && (
                <div className="prop">
                    <p className="prop-label">Listing End Date</p>
                    <p className="prop-value">{formatDate(new Date(voucher.available_till))}</p>
                    <hr className="divider"></hr>
                </div>
            )}
            <div className="prop">
                <p className="prop-label">Usage End Date</p>
                <p className="prop-value">{formatDate(new Date(voucher.valid_till))}</p>
            </div>
            <hr className="divider"></hr>
            {!isPreview ? (
                <>
                    <div className="actions">
                        <div
                            className={`purchases ${purchases.length === 0 ? "disbled" : ""}`}
                            onClick={setPurchaseListHandler}
                            title={purchases.length === 0 ? "No Purchases" : ""}
                        >
                            <img src={PurchaseIcon} alt="Purchase Icon" />
                            <span></span>
                            <span>Purchases</span>
                        </div>
                        {isCancelAvailable && voucher.status === "active" && (
                            <div
                                className="cancel"
                                title="Cancel Voucher"
                                onClick={onCancelVoucher}
                            >
                                <img src={DeleteIcon} alt="Cancel Icon" />
                                <span>Cancel</span>
                            </div>
                        )}
                    </div>
                    <hr className="divider"></hr>
                    {errorMsg && <ErrorMsg message={errorMsg} color="orange" />}
                </>
            ) : (
                <Button label="Buy" color="green" disabled={true} />
            )}
            {purchases.length !== 0 && (
                <Purchases
                    visible={purchaseList}
                    list={purchases}
                    onClickEvent={() => setPurchaseList(false)}
                />
            )}
        </div>
    );
}
