import ArrowIcon from "./../../assets/images/icons/drawer-arrow.svg";
import Comment from "./Comment";

export default function Comments({
    list = [],
    visible = false,
    onClickEvent,
    addReply,
    deleteComment,
}) {
    return (
        <div className={`Comments ${visible ? "show" : ""} `}>
            <div className="Comments-Header">
                <span>Comments:</span>
                <img src={ArrowIcon} alt="arrow icon" onClick={onClickEvent} />
            </div>
            {list.map((comment, i) => {
                return (
                    <Comment
                        key={i}
                        comment={comment}
                        addReply={(reply) => addReply(comment.id, reply)}
                        deleteComment={(isReply) => deleteComment(isReply)}
                    />
                );
            })}
            <hr className="divider"></hr>
        </div>
    );
}
