import React, { useState } from "react";

export default function TabMenu({ onClickEvent }) {
    const [activeList, setActiveList] = useState("all");

    const onClickHandler = (e) => {
        setActiveList(e.target.id);
        onClickEvent(e.target.id);
    };

    return (
        <div className="Tab-Menu">
            <button
                id="all"
                className={activeList === "all" ? "active" : ""}
                onClick={onClickHandler}
                disabled={activeList === "all"}
            >
                All
            </button>
            <button
                id="active"
                className={activeList === "active" ? "active" : ""}
                onClick={onClickHandler}
                disabled={activeList === "active"}
            >
                Active
            </button>
            <button
                id="sold"
                className={activeList === "sold" ? "active" : ""}
                onClick={onClickHandler}
                disabled={activeList === "sold"}
            >
                Sold-out
            </button>
            <button
                id="expired"
                className={activeList === "expired" ? "active" : ""}
                onClick={onClickHandler}
                disabled={activeList === "expired"}
            >
                Unlisted
            </button>
            <button
                id="cancelled"
                className={activeList === "cancelled" ? "active" : ""}
                onClick={onClickHandler}
                disabled={activeList === "cancelled"}
            >
                Cancelled
            </button>
        </div>
    );
}
