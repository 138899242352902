export const reducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_VALUE":
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [action.field]: action.data,
                },
            };
        case "TAGGED":
            return {
                ...state,
                tagged: action.tagged,
                formData: {
                    ...state.formData,
                    tags: action.tagsID,
                },
            };
        default:
            return state;
    }
};
