import React, { useRef, useEffect } from "react";

export default function LoadMore({ onLoad }) {
    const loader = useRef(null);

    useEffect(() => {
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0,
        };
        const handleObserver = (entities) => {
            const target = entities[0];
            if (target.isIntersecting) {
                onLoad();
            }
        };
        // initialize IntersectionObserver and attaching to 'loading' div
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [onLoad]);

    return <div className="loading" ref={loader}></div>;
}
